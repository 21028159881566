import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import PageLoader from "@/components/back/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardContent } from "@/components/back/Card";
import OptionSelectionModal from "@/components/ui/modals/OptionSelectionModal";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";
import toast from "react-hot-toast";

interface EditOptionListProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  optionListId: number | null;
}

interface OptionList {
  id: number;
  name: string;
  description: string;
  options: Option[];
  required: boolean;
  option_type: string;
  max_selections?: number | null;
}

interface Option {
  id: number;
  name: string;
}

const EditOptionList: React.FC<EditOptionListProps> = ({
  onMenuItemClick,
  optionListId,
}) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [optionList, setOptionList] = useState<OptionList>({
    id: 0,
    name: "",
    description: "",
    options: [],
    required: false,
    option_type: "increment_decrement",
  });
  const [originalOptionList, setOriginalOptionList] =
    useState<OptionList | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchOptionList = async () => {
      try {
        if (!loading && axiosInstance && optionListId) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${optionListId}/`);
          setOptionList(response.data);
          setOriginalOptionList(response.data);
          setSelectedOptionIds(
            response.data.options.map((option: Option) => option.id)
          );
        }
      } catch (error) {
        console.error("Error fetching option list:", error);
        toast.error("Failed to fetch option list details.");
      }
    };

    if (optionListId) {
      fetchOptionList();
    }
  }, [axiosInstance, loading, optionListId]);

  useEffect(() => {
    if (originalOptionList) {
      const isChanged =
        JSON.stringify(optionList) !== JSON.stringify(originalOptionList);
      setIsDirty(isChanged);
    }
  }, [optionList, originalOptionList]);

  const handleRemoveOption = (optionId: number) => {
    setOptionList((prev) => ({
      ...prev,
      options: prev.options.filter((option) => option.id !== optionId),
    }));
  };

  const handleUpdateOptionList = async () => {
    setIsSaving(true);
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
        const updatedOptionList = {
          name: optionList.name,
          description: optionList.description,
          branch: user.selectedBranch.id,
          options: optionList.options.map((option) => option.id),
          required: optionList.required,
          option_type: optionList.option_type,
          max_selections: optionList.max_selections,
        };
        const response = await axiosInstance.put(
          `${apiUrl}${optionListId}/`,
          updatedOptionList
        );
        setOptionList(response.data);
        setOriginalOptionList(response.data);
        setIsDirty(false);
        toast.success("Option list updated successfully");
        onMenuItemClick("optionList", "Option list updated successfully");
      }
    } catch (error) {
      console.error("Error updating option list:", error);
      toast.error("Failed to update option list");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    if (originalOptionList) {
      setOptionList(originalOptionList);
      setIsDirty(false);
    }
  };

  const handleOptionSave = (newSelectedOptionIds: number[]) => {
    const selectedOptions = newSelectedOptionIds.map(
      (id) =>
        optionList.options.find((option) => option.id === id) || {
          id,
          name: "",
        }
    );

    setOptionList((prev) => ({
      ...prev,
      options: selectedOptions,
    }));
    setSelectedOptionIds(newSelectedOptionIds);
    setIsOptionModalOpen(false);
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {optionListId ? (
          <>
            <h1 className="text-xl font-bold mb-4">{t("Edit Option List")}</h1>

            {/* General Information Section */}
            <Card className="mb-4">
              <CardHeader>
                <h2 className="text-lg font-semibold">
                  {t("General Information")}
                </h2>
              </CardHeader>
              <CardContent>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="optionListName"
                  >
                    {t("Option List Name")}
                  </label>
                  <input
                    type="text"
                    id="optionListName"
                    value={optionList.name}
                    onChange={(e) =>
                      setOptionList({ ...optionList, name: e.target.value })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="description"
                  >
                    {t("Description")}
                  </label>
                  <textarea
                    id="description"
                    value={optionList.description}
                    onChange={(e) =>
                      setOptionList({
                        ...optionList,
                        description: e.target.value,
                      })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm h-24"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="optionType"
                  >
                    {t("Option Type")}
                  </label>
                  <select
                    id="optionType"
                    value={optionList.option_type}
                    onChange={(e) =>
                      setOptionList({
                        ...optionList,
                        option_type: e.target.value,
                        max_selections: null,
                      })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  >
                    <option value="multiple_choice">
                      {t("Multiple Choice")}
                    </option>
                    <option value="checkbox">{t("Checkbox")}</option>
                    <option value="increment_decrement">
                      {t("Increment/Decrement")}
                    </option>
                  </select>
                </div>
                {(optionList.option_type === "checkbox" ||
                  optionList.option_type === "increment_decrement") && (
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="maxSelections"
                    >
                      {t("Max Selections")}
                    </label>
                    <input
                      type="number"
                      id="maxSelections"
                      value={optionList.max_selections ?? ""}
                      onChange={(e) =>
                        setOptionList({
                          ...optionList,
                          max_selections: parseInt(e.target.value) || null,
                        })
                      }
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={optionList.required}
                      onChange={(e) =>
                        setOptionList({
                          ...optionList,
                          required: e.target.checked,
                        })
                      }
                      className="form-checkbox h-5 w-5 text-red-600"
                    />
                    <span className="ml-2 text-gray-700">{t("Required")}</span>
                  </label>
                </div>
              </CardContent>
            </Card>

            {/* Linked Options Section */}
            <Card className="mb-4">
              <CardHeader className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">{t("Linked Options")}</h2>
                <button
                  onClick={() => setIsOptionModalOpen(true)}
                  className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  {t("Edit")}
                </button>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {optionList.options.map((option) => (
                    <span
                      key={option.id}
                      className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                    >
                      {option.name}
                      <button
                        onClick={() => handleRemoveOption(option.id)}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </span>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("optionList")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleUpdateOptionList}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                disabled={!isDirty || isSaving}
              >
                {t("Update")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("No option list selected")}</p>
        )}

        <OptionSelectionModal
          isOpen={isOptionModalOpen}
          onClose={() => setIsOptionModalOpen(false)}
          onSave={handleOptionSave}
          initialSelectedOptions={selectedOptionIds}
        />

        <ContextualSaveBar
          isDirty={isDirty}
          onSave={handleUpdateOptionList}
          onDiscard={handleDiscard}
          isSaving={isSaving}
        />
      </div>
    </PageLoader>
  );
};

export default EditOptionList;
