import Spinner from "@/components/front/Loader/Spinner";
import ProductCard from "@/components/front/ProductCard";
// import debounce from "lodash/debounce";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";

import React, {
  Suspense,
  //   useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "@/assets/css/ProductList.css"; // Import the custom CSS file

interface ProductListProps {
  onContinueCheckout: () => void;
  onContinueCart: () => void;
}

interface Product {
  id: number;
  product_option_lists: any[];
  category_name: string;
  branch: number;
  name: string;
  description: string;
  image: string;
  color: string;
  unit_price: string;
  stock: number;
  menu_display_name: string;
  sku: string;
  reorder_level: number;
  status: string;
  created_at: string;
  is_active: boolean;
  category: number;
  discount: any;
  quantity: number;
  is_upsell: boolean;
}

const ProductList: React.FC<ProductListProps> = ({
  onContinueCheckout,
  onContinueCart,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [axiosInstance] = useAxiosInstance();
  const cartItems = useSelector((state: RootState) => state.foodCart.items);

  useEffect(() => {
    const fetchUpsellProducts = async () => {
      try {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
        const response = await axiosInstance.get(`${apiUrl}?is_upsell=true`);
        setProducts(response.data.results);
      } catch (error) {
        console.error("Error fetching upsell products:", error);
      }
    };

    fetchUpsellProducts();
  }, [axiosInstance]);

  const cartProductIds = cartItems.map((item) => item.id);
  return (
    <div className="product-list-container">
      <button
        onClick={onContinueCart}
        className="text-black-500 mb-4 flex items-center"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
      </button>
      <h1 className="text-xl font-bold mb-4">
        {"Did you forget something in your cart?"}
      </h1>
      <div className="product-list">
        {products
          .filter((product) => !cartProductIds.includes(product.id))
          .map((product) => (
            <LazyLoadComponent
              key={product.id}
              placeholder={
                <div className="h-64 w-full bg-gray-200 animate-pulse"></div>
              }
            >
              <Suspense fallback={<Spinner />}>
                <ProductCard product={product} />
              </Suspense>
            </LazyLoadComponent>
          ))}
      </div>

      <button
        onClick={onContinueCheckout}
        className="bg-red-500 text-white py-2 px-4 rounded mt-4 w-full"
      >
        {"Continue checkout"}
      </button>
    </div>
  );
};

export default ProductList;
