
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSort,
  faSortDown,
  faSortUp,
  faEdit,
  faTrashAlt,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "@hello-pangea/dnd";

export interface Column<T> {
  key: keyof T | "action" | "image";
  header: string;
  sortable?: boolean;
  render?: (item: T) => React.ReactNode;
  bold?: boolean;
}

interface DataTableProps<T> {
  data: T[];
  columns: Column<T>[];
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  sortState: { field: keyof T; direction: "asc" | "desc" };
  onSortChange: (field: keyof T) => void;
  onPageChange: (page: number) => void;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  getStatusColor: (item: T) => string;
  renderMobileCard: (item: T) => React.ReactNode;
  onDragEnd?: OnDragEndResponder;
}

function DataTable<T extends { id: number }>({
  data,
  columns,
  itemsPerPage,
  currentPage,
  totalItems,
  sortState,
  onSortChange,
  onPageChange,
  onEdit,
  onDelete,
  getStatusColor,
  renderMobileCard,
  onDragEnd,
}: DataTableProps<T>) {
  const { t } = useTranslation();

  const renderCellContent = (item: T, column: Column<T>): React.ReactNode => {
    if (column.render) {
      return column.render(item);
    }
    if (column.key === "action") {
      return null;
    }
    if (column.key === "image") {
      return item[column.key as keyof T] ? (
        <img
          src={item[column.key as keyof T] as string}
          alt={item["name" as keyof T] as string}
          className="w-16 h-16"
        />
      ) : null;
    }
    const value = item[column.key as keyof T];
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      return value.toString();
    }
    return null;
  };

  
  const renderDesktopTable = () => (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="overflow-x-auto">
        {onDragEnd ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="table">
              {(provided) => (
                <table {...provided.droppableProps} ref={provided.innerRef} className="min-w-full divide-y divide-gray-200">
                  {renderTableContent(true, provided)}
                </table>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            {renderTableContent(false)}
          </table>
        )}
      </div>
    </div>
  );

  const renderTableContent = (isDraggable: boolean, provided?: any) => (
    <>
      <thead className="bg-gray-50">
        <tr>
          {isDraggable && <th className="w-8"></th>}
          {columns.map((column) => (
            <th
              key={column.key.toString()}
              className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                column.sortable ? "cursor-pointer" : ""
              }`}
              onClick={() =>
                column.sortable &&
                column.key !== "action" &&
                column.key !== "image" &&
                onSortChange(column.key as keyof T)
              }
            >
              <span className="flex items-center">
                {column.header}
                {column.sortable &&
                  column.key !== "action" &&
                  column.key !== "image" && (
                    <FontAwesomeIcon
                      icon={
                        sortState.field === column.key
                          ? sortState.direction === "asc"
                            ? faSortUp
                            : faSortDown
                          : faSort
                      }
                      className="ml-1"
                      style={{ marginLeft: "4px" }}
                    />
                  )}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-100">
        {data.map((item, index) => (
          isDraggable ? (
            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
              {(provided) => (
                <tr
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  {renderTableRow(item, provided)}
                </tr>
              )}
            </Draggable>
          ) : (
            <tr key={item.id}>
              {renderTableRow(item)}
            </tr>
          )
        ))}
        {isDraggable && provided && provided.placeholder}
      </tbody>
    </>
  );

  const renderTableRow = (item: T, provided?: any) => (
    <>
      {provided && (
        <td className="w-8 px-6 py-4">
          <div {...provided.dragHandleProps} className="cursor-move">
            <FontAwesomeIcon icon={faGripVertical} className="text-gray-400" />
          </div>
        </td>
      )}
      {columns.map((column) => (
        <td
          key={column.key.toString()}
          className={`px-6 py-4 text-gray-900 ${
            column.bold ? "font-bold" : ""
          }`}
        >
          {column.key === "action" ? (
            <>
              <button
                onClick={() => onEdit(item.id)}
                className="mr-2"
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-gray-500 hover:text-gray-800 fa-2x"
                />
              </button>
              <button onClick={() => onDelete(item.id)}>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="text-red-600 hover:text-red-800 fa-2x"
                />
              </button>
            </>
          ) : (
            renderCellContent(item, column)
          )}
        </td>
      ))}
    </>
  );
 
  
  const renderMobileTable = () => (
    onDragEnd ? (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="mobile-table">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="grid grid-cols-1 gap-4">
              {renderMobileItems(true, provided)}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <div className="grid grid-cols-1 gap-4">
        {renderMobileItems(false)}
      </div>
    )
  );

  const renderMobileItems = (isDraggable: boolean, provided?: any) => (
    <>
      {data.map((item, index) => (
        isDraggable ? (
          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="p-4 bg-white rounded-lg shadow flex justify-between items-center relative"
              >
                {renderMobileCard(item)}
                <div
                  className={`absolute top-0 bottom-0 left-0 w-1.5 ${getStatusColor(
                    item
                  )}`}
                ></div>
              </div>
            )}
          </Draggable>
        ) : (
          <div
            key={item.id}
            className="p-4 bg-white rounded-lg shadow flex justify-between items-center relative"
          >
            {renderMobileCard(item)}
            <div
              className={`absolute top-0 bottom-0 left-0 w-1.5 ${getStatusColor(
                item
              )}`}
            ></div>
          </div>
        )
      ))}
      {isDraggable && provided && provided.placeholder}
    </>
  );

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return (
      <div className="flex flex-col items-center pt-4">
        <div className="mb-4">
          <p className="text-sm text-gray-700">
            {t("back.management.table.showing")}{" "}
            <span className="font-medium">
              {(currentPage - 1) * itemsPerPage + 1}
            </span>{" "}
            {t("back.management.table.to")}{" "}
            <span className="font-medium">
              {Math.min(currentPage * itemsPerPage, totalItems)}
            </span>{" "}
            {t("back.management.table.of")}{" "}
            <span className="font-medium">{totalItems}</span>{" "}
            {t("back.management.table.results")}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage <= 1}
            className="p-2 disabled:opacity-50"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="flex items-center space-x-2">
            {!isNaN(totalPages) &&
              getPageNumbers(currentPage, totalPages).map((page, index) => (
                <button
                  key={index}
                  onClick={() => typeof page === "number" && onPageChange(page)}
                  className={`px-2 py-1 rounded ${
                    currentPage === page
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  disabled={page === "..." ? true : false}
                >
                  {page}
                </button>
              ))}
          </div>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
            className="p-2 disabled:opacity-50"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
        <div className="hidden md:block">{renderDesktopTable()}</div>
      <div className="md:hidden">{renderMobileTable()}</div>
      {renderPagination()}
    </>
  );
}

export default DataTable;

// Helper function to generate pagination numbers with ellipsis
const getPageNumbers = (currentPage: number, totalPages: number) => {
  const delta = 1;
  const range = [];
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(totalPages - 1, currentPage + delta);
    i++
  ) {
    range.push(i);
  }
  if (currentPage - delta > 2) {
    range.unshift("...");
  }
  if (currentPage + delta < totalPages - 1) {
    range.push("...");
  }
  range.unshift(1);
  if (totalPages > 1) {
    range.push(totalPages);
  }
  return range;
};