import DateFilterButton from "@/components/back/DateFilterButton";
import FilterOptionsButton from "@/components/back/FilterOptionsButton";
import SearchInput from "@/components/back/SearchInput";
import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faSearch,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/back/PageHeader";
import DataTable, { Column } from "@/components/back/DataTable";
import DeleteConfirmationDialog from "@/components/back/DeleteConfirmationDialog";
import { DropResult } from "@hello-pangea/dnd";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";

interface CategoryItem {
  id: number;
  name: string;
  num_sub_categories: number;
  num_products: number;
  status: string;
  created_at: string;
  photo: string;
  ranking: number;
}

interface SortState {
  field: keyof CategoryItem;
  direction: "asc" | "desc";
}

interface FilterState {
  status: string;
}

interface CategoryProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => void;
  successMessage: string;
}

export default function Category({
  onMenuItemClick,
  successMessage,
}: CategoryProps) {
  const { t } = useTranslation();
  const itemsPerPage = 25;
  const [categoryItems, setCategoryItems] = useState<CategoryItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    direction: "asc",
  });
  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterState, setFilterState] = useState<FilterState>({
    status: "",
  });

  const columns: Column<CategoryItem>[] = [
    {
      key: "name",
      header: t("back.management.menu.category.table.category"),
      sortable: true,
      bold: true,
    },
    {
      key: "num_products",
      header: t("back.management.menu.category.table.product"),
      sortable: true,
    },
    {
      key: "status",
      header: t("back.management.menu.category.table.status"),
      bold: true,
    },
    {
      key: "created_at",
      header: t("back.management.menu.category.table.date"),
      sortable: true,
    },
    {
      key: "action",
      header: t("back.management.menu.category.table.action"),
    },
  ];

  const getStatusColor = (item: CategoryItem) => {
    switch (item.status) {
      case "Active":
        return "bg-green-500";
      case "No Product":
        return "bg-yellow-500";
      case "Inactive":
        return "bg-red-500";
      case "Draft":
        return "bg-gray-500";
      default:
        return "bg-gray-500";
    }
  };

  const renderMobileCard = (item: CategoryItem) => (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center">
        {item.photo && (
          <img src={item.photo} alt={item.name} className="w-16 h-16 mr-4" />
        )}
        <div>
          <p className="font-bold text-gray-900">{item.name}</p>
          <p className="text-gray-700">
            {item.num_products}{" "}
            {t("back.management.menu.category.table.product")}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-between h-full">
        <button
          onClick={() => editItem(item.id)}
          className="mb-2 text-gray-600 hover:text-gray-800 fa-2x"
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
          onClick={() => openDeleteConfirmation(item.id)}
          className="text-red-600 hover:text-red-800 fa-2x"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    </div>
  );

  const fetchCategoryItems = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
        let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;
        if (searchQuery) {
          url += `&name=${encodeURIComponent(searchQuery)}`;
        }
        if (filterStartDate) {
          const formattedStartDate = filterStartDate.toISOString();
          url += `&created_at__gt=${formattedStartDate}`;
        }
        if (filterEndDate) {
          const formattedEndDate = filterEndDate.toISOString();
          url += `&created_at__lt=${formattedEndDate}`;
        }
        if (filterState.status) {
          url += `&status=${filterState.status}`;
        }

        const response = await axiosInstance.get(url);

        console.log("this is prelim", response.data.results);
        let fetchedItems = response.data.results.map((item: CategoryItem) => {
          // Convert and format the 'created_at' date
          const zonedDate = utcToZonedTime(item.created_at, "America/Chicago");
          const formattedDate = format(zonedDate, "MM/dd/yyyy, HH:mm");
          return { ...item, created_at: formattedDate };
        });

        fetchedItems.sort((a: CategoryItem, b: CategoryItem) => {
          if (sortState.field === "created_at") {
            // Convert back to dates for comparison
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return sortState.direction === "asc"
              ? dateA.getTime() - dateB.getTime()
              : dateB.getTime() - dateA.getTime();
          } else {
            const aValue = a[sortState.field];
            const bValue = b[sortState.field];
            if (typeof aValue === "number" && typeof bValue === "number") {
              return sortState.direction === "asc"
                ? aValue - bValue
                : bValue - aValue;
            } else if (
              typeof aValue === "string" &&
              typeof bValue === "string"
            ) {
              return sortState.direction === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }
          }
          return 0;
        });
        //sorting them by rank
        fetchedItems.sort(
          (a: CategoryItem, b: CategoryItem) => a.ranking - b.ranking
        );
        console.log("this is fetched items 2", fetchedItems);
        setCategoryItems(fetchedItems);
        setTotalItems(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching category items:", error);
    }
  };

  const handleFilterStartDateChange = (date: Date | null) => {
    setFilterStartDate(date);
  };

  const handleFilterEndDateChange = (date: Date | null) => {
    setFilterEndDate(date);
  };

  const handleFilterChange = (field: keyof FilterState, value: string) => {
    setFilterState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRemoveFilters = () => {
    setFilterState({
      status: "",
    });
    setCurrentPage(1);
    fetchCategoryItems();
  };

  useEffect(() => {
    if (successMessage) {
      setBannerMessage(successMessage);
      setShowBanner(true);
      setIsSuccess(true);
      console.log("Banner message set:", successMessage);
      console.log("Show banner:", true);
    }
  }, [successMessage]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchCategoryItems();
    }, 500);

    return () => clearTimeout(timerId);
  }, [
    currentPage,
    searchQuery,
    sortState,
    axiosInstance,
    loading,
    user,
    filterStartDate,
    filterEndDate,
  ]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  useEffect(() => {
    const fetchData = async () => {
      setCurrentPage(1);
      await fetchCategoryItems();
    };

    fetchData();
  }, [filterState]);

  const editItem = (id: number) => {
    onMenuItemClick("editCategory", "", id);
  };

  const openDeleteConfirmation = (id: number) => {
    setItemToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          await axiosInstance.delete(`${apiUrl}${itemToDelete}`);
          fetchCategoryItems();
          setBannerMessage(t("back.management.menu.category.successdelete"));
          setIsSuccess(true);
          setShowBanner(true);
        }
      } catch (error) {
        console.error("Error deleting menu item:", error);
        setBannerMessage(t("back.management.menu.category.faileddelete"));
        setIsSuccess(false);
        setShowBanner(true);
      }
      closeDeleteConfirmation();
    }
  };

  const handleSortChange = (field: keyof CategoryItem) => {
    setSortState((prevState) => ({
      field,
      direction:
        prevState.field === field && prevState.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Description", "Price"],

      ...categoryItems.map((item) => [
        item.id,
        item.name,
        item.num_sub_categories,
        item.num_products,
        item.created_at,
        item.status,
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "category.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const [isDirty, setIsDirty] = useState(false);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(categoryItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCategoryItems(items);
    setIsDirty(true);
  };

  const handleSave = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl = `${process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION}bulk_update_rankings/`;
        const updatedRankings = categoryItems.map((category, index) => ({
          id: category.id,
          new_ranking: index,
        }));

        await axiosInstance.post(apiUrl, {
          rankings: updatedRankings,
          branch: user.selectedBranch.id,
        });

        setIsDirty(false);
        setBannerMessage("Successfully Reordered");
        setIsSuccess(true);
        setShowBanner(true);
      }
    } catch (error) {
      console.error("Error saving new category order:", error);
      setBannerMessage(t("Failed to reorder"));
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  const handleDiscard = () => {
    fetchCategoryItems();
    setIsDirty(false);
  };
  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}
          {isDirty && (
            <ContextualSaveBar
              isDirty={isDirty}
              onSave={handleSave}
              onDiscard={handleDiscard}
              isSaving={false}
            />
          )}
          <PageHeader
            title={t("back.management.menu.category.title")}
            exportLabel={t("back.management.menu.category.export")}
            addLabel={t("back.management.menu.category.addcategory")}
            onExport={downloadCSV}
            onAdd={() => onMenuItemClick("addCategory")}
          />

          <DeleteConfirmationDialog
            isOpen={deleteConfirmationOpen}
            onClose={closeDeleteConfirmation}
            onConfirm={confirmDelete}
            title={t("back.management.menu.category.deleteConfirmation.title")}
            message={t(
              "back.management.menu.category.deleteConfirmation.message"
            )}
          />

          {/* Desktop Layout */}
          <div className="desktop-layout">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md mr-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <SearchInput
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t(
                    "back.management.menu.category.searchCategory"
                  )}
                />
              </div>
              <div className="relative">
                <DateFilterButton
                  onStartDateChange={handleFilterStartDateChange}
                  onEndDateChange={handleFilterEndDateChange}
                  startDate={filterStartDate}
                  endDate={filterEndDate}
                  labelText={t("back.management.menu.category.selectdate")}
                />
                <FilterOptionsButton
                  filterOptions={[
                    {
                      label: t("back.management.menu.category.status"),
                      value: filterState.status,
                      onChange: (value) => handleFilterChange("status", value),
                      placeholder: t(
                        "back.management.menu.category.selectStatus"
                      ),
                      type: "select",
                      options: [
                        {
                          value: "Active",
                          label: t("back.management.menu.category.active"),
                        },
                        {
                          value: "Inactive",
                          label: t("back.management.menu.category.inactive"),
                        },
                        {
                          value: "No Product",
                          label: t("back.management.menu.category.noProduct"),
                        },
                        {
                          value: "Draft",
                          label: t("back.management.menu.category.draft"),
                        },
                      ],
                    },
                  ]}
                  onRemoveFilters={handleRemoveFilters}
                />
              </div>
            </div>

            <DataTable
              data={categoryItems}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={getStatusColor}
              renderMobileCard={renderMobileCard}
              onDragEnd={handleDragEnd}
            />
          </div>

          {/* Mobile Layout */}
          <div className="mobile-layout">
            <div className="flex flex-col mb-4">
              <div className="relative flex-1 max-w-md mb-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <SearchInput
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t(
                    "back.management.menu.category.searchCategory"
                  )}
                />
              </div>
              <div className="relative mb-4">
                <DateFilterButton
                  onStartDateChange={handleFilterStartDateChange}
                  onEndDateChange={handleFilterEndDateChange}
                  startDate={filterStartDate}
                  endDate={filterEndDate}
                  labelText={t("back.management.menu.category.selectdate")}
                />

                <FilterOptionsButton
                  filterOptions={[
                    {
                      label: t("back.management.menu.category.status"),
                      value: filterState.status,
                      onChange: (value) => handleFilterChange("status", value),
                      placeholder: t(
                        "back.management.menu.category.selectStatus"
                      ),
                      type: "select",
                      options: [
                        {
                          value: "Active",
                          label: t("back.management.menu.category.active"),
                        },
                        {
                          value: "Inactive",
                          label: t("back.management.menu.category.inactive"),
                        },
                        {
                          value: "No Product",
                          label: t("back.management.menu.category.noProduct"),
                        },
                        {
                          value: "Draft",
                          label: t("back.management.menu.category.draft"),
                        },
                      ],
                    },
                  ]}
                  onRemoveFilters={handleRemoveFilters}
                />
              </div>
            </div>
            <DataTable
              data={categoryItems}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={getStatusColor}
              renderMobileCard={renderMobileCard}
              onDragEnd={handleDragEnd}
            />
          </div>
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}
