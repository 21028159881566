import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

export interface Customer {
  name: string;
  email: string;
  avatar?: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
  wallet_points: string;
}

interface CustomerContextType {
  customer: Customer | null;
  login: (customerData: Customer) => void;
  logout: () => void;
}

const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined
);

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error("useCustomer must be used within a CustomerProvider");
  }
  return context;
};

interface CustomerProviderProps {
  children: ReactNode;
}

export const CustomerProvider = ({ children }: CustomerProviderProps) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCustomerData = localStorage.getItem("customer_data");
    if (storedCustomerData) {
      const customerData: Customer = JSON.parse(storedCustomerData);
      setCustomer(customerData);
    }
  }, []);

  const login = (customerData: Customer) => {
    setCustomer(customerData);
    localStorage.setItem("customer_data", JSON.stringify(customerData));
  };

  const logout = () => {
    setCustomer(null);
    localStorage.removeItem("customer_data");
    navigate("/store");
  };

  return (
    <CustomerContext.Provider value={{ customer, login, logout }}>
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext };
