import { useCustomer } from "@/contexts/CustomerContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CustomerProfile from "./CustomerProfile";
import { useAddressAutocomplete } from "./useAddressAutoComplete";

const CustomerRegister = () => {
  const { login } = useCustomer();
  const [axiosInstance] = useAxiosInstance();
  const [form, setForm] = useState({
    name: "",
    avatar: "",
    email: "",
    phone: "",
    wallet_points: "0.00",
  });

  const { address, handlePlaceSelected, handleInputChange } =
    useAddressAutocomplete({
      street: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!searchParams.has("phone")) {
      navigate("/customer-login");
      return;
    }
    setForm((prev) => ({
      ...prev,
      phone: `+${searchParams.get("phone")}` ?? "",
    }));
  }, [navigate, searchParams]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = {
        ...form,
        ...address,
      };
      const res = await axiosInstance.post("/api/customer-profile", data);
      if (res.status === 201) {
        toast.success("Profile created successfully");
        login(data);
        navigate("/customer-profile");
      }
    } catch (error) {
      toast.error("Failed to create profile");
      console.error(error);
    }
  };

  return (
    <div className="mt-4">
      <div className="ml-4">
        <Link to="/store" className="text-blue-500 hover:underline">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </Link>
      </div>
      <CustomerProfile
        handleSubmit={handleSubmit}
        form={form}
        setForm={setForm}
        address={address}
        handlePlaceSelected={handlePlaceSelected}
        handleInputChange={handleInputChange}
      />
    </div>
  );
};

export default CustomerRegister;
