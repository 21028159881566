

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useUser } from "@/contexts/UserContext";
import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

interface CategoryItem {
  id: number;
  name: string;
  ranking: number;
}

interface RankingCategoryProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => void;
  successMessage: string;
}

export default function AdjustCategoryRankings({
  onMenuItemClick,
  successMessage,
}: RankingCategoryProps) {
  const { t } = useTranslation();
  const { user } = useUser();
  const [categories, setCategories] = useState<CategoryItem[]>([]);
  const [axiosInstance, isFetching] = useAxiosInstance();
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          const response = await axiosInstance.get(
            `${apiUrl}?branch=${user.selectedBranch.id}`
          );
          console.log("tjhis is tghe order from here", response.data.results)
          setCategories(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setBannerMessage("Failed to load categories");
        setIsSuccess(false);
        setShowBanner(true);
      }
    };

    fetchCategories();
  }, [axiosInstance, user]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedCategories = Array.from(categories);
    const [movedCategory] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, movedCategory);

    const updatedCategories = reorderedCategories.map((category, index) => ({
      ...category,
      ranking: index,
    }));

    setCategories(updatedCategories);
    setIsDirty(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const apiUrl = `${process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION}bulk_update_rankings/`;
      const updatedRankings = categories.map((category, index) => ({
        id: category.id,
        new_ranking: index,
      }));
  
      await axiosInstance.post(apiUrl, {
        rankings: updatedRankings,
        branch: user?.selectedBranch?.id,
      });
  
      setIsSuccess(true);
      setBannerMessage("Successfully Reordered");
    } catch (error) {
      console.error("Error saving category rankings:", error);
      setIsSuccess(false);
      setBannerMessage("Failed to reorder");
    } finally {
      setIsSaving(false);
      setShowBanner(true);
      setIsDirty(false);
    }
  };

  const handleDiscard = () => {
    // Reset to original order
    setCategories(prevCategories => [...prevCategories].sort((a, b) => a.ranking - b.ranking));
    setIsDirty(false);
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <ContextualSaveBar
          isDirty={isDirty}
          onSave={handleSave}
          onDiscard={handleDiscard}
          isSaving={isSaving}
        />
        <h1 className="text-xl font-bold mb-4">{t("Adjust Category Rankings")}</h1>
        <p className="mb-4 text-gray-600">
          {t("Drag and drop to reorder category rankings")}
        </p>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="categories">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-4"
              >
                {categories.map((category, index) => (
                  <Draggable
                    key={category.id}
                    draggableId={category.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex items-center bg-white p-4 shadow rounded-lg border border-gray-200"
                      >
                        <span className="mr-2 cursor-grab text-gray-400">
                          ⋮⋮
                        </span>
                        <span className="flex-1">{category.name}</span>
                        <span className="text-gray-500">{index + 1}</span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </PageLoader>
  );
}