import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faCircle,
  faImage,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddOptionProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}

interface OptionList {
  id: number;
  name: string;
}

const AddOption = ({ onMenuItemClick, setSuccessMessage }: AddOptionProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [optionLists, setOptionLists] = useState<OptionList[]>([]);
  const [selectedOptionListId, setSelectedOptionListId] = useState<
    number | null
  >(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [optionColor, setOptionColor] = useState("#ffffff");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");
  const [surcharge, setSurcharge] = useState("");

  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const colorOptions = [
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
    { label: "Yellow", value: "#ffff00" },
    { label: "Purple", value: "#800080" },
    { label: "Orange", value: "#ffa500" },
    { label: "Black", value: "#000000" },
  ];

  // const handleColorChange = (value: string) => {
  //   if (value === "") {
  //     setOptionColor("#ffffff");
  //   } else {
  //     const selectedColor = colorOptions.find(
  //       (option) => option.value === value
  //     );
  //     setOptionColor(selectedColor ? selectedColor.value : "");
  //   }
  // };

  // const fileToBase64 = (
  //   file: File,
  //   callback: (result: string | ArrayBuffer | null) => void
  // ): void => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => callback(reader.result);
  //   reader.onerror = (error) =>
  //     console.log("Error converting file to Base64:", error);
  // };

  // const handleFileChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ): void => {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     setImagePreviewUrl(URL.createObjectURL(file));
  //     setIsImageUploaded(true);

  //     fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
  //       if (typeof base64Result === "string") {
  //         const base64Data = base64Result.split(",")[1];
  //         setImageBase64(base64Data);
  //       }
  //     });
  //   }
  // };

  // const handleRemoveImage = () => {
  //   setImagePreviewUrl("");
  //   setIsImageUploaded(false);
  // };

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
    const fetchOptionLists = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const response = await axiosInstance.get(
            `${apiUrl}?branch=${user.selectedBranch.id}`
          );
          setOptionLists(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching option lists:", error);
      }
    };

    fetchOptionLists();
  }, [axiosInstance, loading, user?.selectedBranch]);

  const handleAddOption = async () => {
    const optionData = {
      branch: user?.selectedBranch?.id,
      name,
      description,
      color: optionColor,
      surcharge: surcharge || "0",
      // image: imageBase64 ? imageBase64 : undefined,
      optionList: selectedOptionListId,
    };
    console.log("Option data:", optionData);
    try {
      if (!loading && axiosInstance) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_OPTION_INFORMATION;
        if (!apiUrl) {
          console.error(
            "API URL for option information is not defined in .env"
          );
          return;
        }
        await axiosInstance.post(apiUrl, optionData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const successMessage = t("back.management.menu.option.successadd");
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("option", successMessage);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setIsSuccess(false);
      const errorMessage = t("back.management.menu.option.failadd");
      setBannerMessage(errorMessage);
      setShowBanner(true);
    }
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.option.addNewOption")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.option.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-full px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="optionName"
              >
                {t("back.management.menu.option.optionName")}
              </label>
              <input
                type="text"
                id="name"
                placeholder={t("back.management.menu.option.enterOptionName")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
            {/* <div className="w-full md:w-1/2 px-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="optionColor"
              >
                {t("back.management.menu.option.optionColor")}
              </label>
              <select
                id="optionColor"
                value={optionColor}
                onChange={(e) => handleColorChange(e.target.value)}
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.option.selectColor")}
                </option>
                {colorOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {optionColor && (
                <FontAwesomeIcon
                  icon={faCircle}
                  color={optionColor}
                  className="mt-2"
                />
              )}
            </div> */}
          </div>
          {/* <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.option.description")}
            </label>
            <textarea
              id="description"
              placeholder={t("back.management.menu.option.enterDescription")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            ></textarea>
          </div> */}
        </div>

        {/* Surcharge Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.option.surcharge")}
          </h2>
          <input
            type="text"
            placeholder={t("back.management.menu.option.enterSurcharge")}
            value={surcharge}
            onChange={(e) => setSurcharge(e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          />
        </div>

        {/* Media Section */}
        {/* <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.option.media")}
          </h2>
          <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
            {t("back.management.menu.option.uploadImage")}
          </p>
          <div
            className={`block w-full border-2 ${
              isImageUploaded ? "border-solid" : "border-dotted"
            } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
          >
            <input
              id="fileUpload"
              type="file"
              className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
              onChange={handleFileChange}
            />
            {imagePreviewUrl ? (
              <>
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  className="max-h-full max-w-full p-2"
                />
                <button
                  onClick={handleRemoveImage}
                  className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                  style={{ width: "30px", height: "30px" }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </>
            ) : (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faImage}
                  size="2x"
                  className="text-gray-400 mb-2"
                />
                <p className="text-gray-600 mb-2">
                  {t("back.management.menu.option.UploadImageText")}
                </p>
              </div>
            )}
          </div>
        </div> */}

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("option")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.option.cancelbutton")}
          </button>
          <button
            onClick={handleAddOption}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.menu.option.confirmbutton")}
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default AddOption;
