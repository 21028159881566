import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import PageLoader from "@/components/back/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardContent } from "@/components/back/Card";
import ProductSelectionModal from "@/components/ui/modals/ProductSelectionModal";
import CategorySelectionModal from "@/components/ui/modals/CategorySelectionModal";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";
import toast from "react-hot-toast";

interface EditPrinterProps {
  onMenuItemClick: (componentName: string) => void;
  printerId: number | null;
}

interface Product {
  id: number;
  name: string;
  menu_display_name: string;
}

interface Category {
  id: number;
  name: string;
}

interface Printer {
  id: number;
  name: string;
  is_primary: boolean;
  is_report_printer: boolean;
  printer_id: number;
  state: string;
  products: Product[];
  categories: Category[];
}

const EditPrinter = ({ onMenuItemClick, printerId }: EditPrinterProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [printer, setPrinter] = useState<Printer>({
    id: 0,
    name: "",
    is_primary: false,
    is_report_printer: false,
    printer_id: 0,
    state: "",
    products: [],
    categories: [],
  });
  const [originalPrinter, setOriginalPrinter] = useState<Printer | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchPrinter = async () => {
      try {
        if (!loading && axiosInstance && printerId) {
          const response = await axiosInstance.get(
            `print/printers/${printerId}/`
          );
          setPrinter(response.data);
          setOriginalPrinter(response.data);
        }
      } catch (error) {
        console.error("Error fetching printer:", error);
        toast.error("Failed to fetch printer details.");
      }
    };

    if (printerId) {
      fetchPrinter();
    }
  }, [axiosInstance, loading, printerId]);

  useEffect(() => {
    if (originalPrinter) {
      const isChanged =
        JSON.stringify(printer) !== JSON.stringify(originalPrinter);
      setIsDirty(isChanged);
    }
  }, [printer, originalPrinter]);

  const handleRemoveCategory = (categoryId: number) => {
    setPrinter((prev) => ({
      ...prev,
      categories: prev.categories.filter((c) => c.id !== categoryId),
    }));
    setIsDirty(true);
  };

  const handleRemoveProduct = (productId: number) => {
    setPrinter((prev) => ({
      ...prev,
      products: prev.products.filter((p) => p.id !== productId),
    }));
    setIsDirty(true);
  };

  const handleUpdateCategories = (updatedCategoryIds: number[]) => {
    const newCategories = updatedCategoryIds.map((id) => {
      const category = printer.categories.find((c) => c.id === id);
      return category ? category : { id, name: "" };
    });
    setPrinter((prev) => ({ ...prev, categories: newCategories }));
  };

  const handleUpdateProducts = (updatedProductIds: number[]) => {
    const newProducts = updatedProductIds.map((id) => {
      const product = printer.products.find((p) => p.id === id);
      return product ? product : { id, name: "", menu_display_name: "" };
    });
    setPrinter((prev) => ({ ...prev, products: newProducts }));
  };

  const handleUpdatePrinter = async () => {
    setIsSaving(true);
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const updatedPrinter = {
          is_primary: printer.is_primary,
          is_report_printer: printer.is_report_printer,
          product_ids: printer.products.map((p) => p.id),
          category_ids: printer.categories.map((c) => c.id),
        };

        const response = await axiosInstance.patch(
          `print/printers/${printerId}/update_printer/`,
          updatedPrinter
        );

        setPrinter(response.data);
        setOriginalPrinter(response.data);
        setIsDirty(false);

        toast.success("Printer updated successfully");
      }
    } catch (error) {
      console.error("Error updating printer:", error);
      toast.error("Failed to update printer");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    if (originalPrinter) {
      setPrinter(originalPrinter);
      setIsDirty(false);
    }
  };

  const handleCategorySave = (selectedCategories: Category[]) => {
    setPrinter((prev) => ({ ...prev, categories: selectedCategories }));
    setIsCategoryModalOpen(false);
  };

  const handleProductSave = (selectedProducts: Product[]) => {
    setPrinter((prev) => ({ ...prev, products: selectedProducts }));
    setIsProductModalOpen(false);
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {printerId ? (
          <>
            <h1 className="text-xl font-bold mb-4">
              {t("back.management.printer.editPrinter")}
            </h1>

            {/* General Information Section */}
            <Card className="mb-4">
              <CardHeader>
                <h2 className="text-lg font-semibold">
                  {t("back.management.printer.generalInfo")}
                </h2>
              </CardHeader>
              <CardContent>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="printerName"
                  >
                    {t("back.management.printer.printerName")}
                  </label>
                  <input
                    type="text"
                    id="printerName"
                    value={printer.name}
                    readOnly
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="printerId"
                  >
                    {t("back.management.printer.printerId")}
                  </label>
                  <input
                    type="number"
                    id="printerId"
                    value={printer.printer_id}
                    readOnly
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100"
                  />
                </div>
                <div className="mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={printer.is_primary}
                      onChange={(e) =>
                        setPrinter({ ...printer, is_primary: e.target.checked })
                      }
                      className="form-checkbox h-5 w-5 text-red-600"
                    />
                    <span className="ml-2 text-gray-700 font-semibold">
                      {t("back.management.printer.isPrimary")}
                    </span>
                  </label>
                </div>
                <div className="mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={printer.is_report_printer}
                      onChange={(e) =>
                        setPrinter({
                          ...printer,
                          is_report_printer: e.target.checked,
                        })
                      }
                      className="form-checkbox h-5 w-5 text-red-600"
                    />
                    <span className="ml-2 text-gray-700 font-semibold">
                      {t("back.management.printer.isReportPrinter")}
                    </span>
                  </label>
                </div>
              </CardContent>
            </Card>

            {/* Status Section */}
            <Card className="mb-4">
              <CardHeader>
                <h2 className="text-lg font-semibold">
                  {t("back.management.printer.status")}
                </h2>
              </CardHeader>
              <CardContent>
                <p className="text-gray-700">
                  {t("back.management.printer.currentStatus")}: {printer.state}
                </p>
              </CardContent>
            </Card>

            {/* Linked Category Section */}
            <Card className="mb-4">
              <CardHeader className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Linked Category</h2>
                <button
                  onClick={() => setIsCategoryModalOpen(true)}
                  className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Edit
                </button>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {printer.categories.map((category) => (
                    <span
                      key={category.id}
                      className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                    >
                      {category.name}
                      <button
                        onClick={() => handleRemoveCategory(category.id)}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </span>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Linked Product Section */}
            <Card className="mb-4">
              <CardHeader className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Linked Product</h2>
                <button
                  onClick={() => setIsProductModalOpen(true)}
                  className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Edit
                </button>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {printer.products.map((product) => (
                    <span
                      key={product.id}
                      className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                    >
                      {product.menu_display_name}
                      <button
                        onClick={() => handleRemoveProduct(product.id)}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </span>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("printer")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.printer.cancelButton")}
              </button>
              <button
                onClick={handleUpdatePrinter}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                disabled={!isDirty || isSaving}
              >
                {t("back.management.printer.updateButton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.printer.noPrinterSelected")}</p>
        )}

        <CategorySelectionModal
          isOpen={isCategoryModalOpen}
          onClose={() => setIsCategoryModalOpen(false)}
          onSave={handleCategorySave}
          initialSelectedCategories={printer.categories.map((c) => c.id)}
        />

        <ProductSelectionModal
          isOpen={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          onSave={handleProductSave}
          initialSelectedProducts={printer.products.map((p) => p.id)}
        />

        <ContextualSaveBar
          isDirty={isDirty}
          onSave={handleUpdatePrinter}
          onDiscard={handleDiscard}
          isSaving={isSaving}
        />
      </div>
    </PageLoader>
  );
};

export default EditPrinter;
