import React from "react";
import { format, parse } from "date-fns";

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

const formatTime = (time: string) => {
  const parsedTime = parse(time, "HH:mm:ss", new Date());
  return format(parsedTime, "HH:mm");
};

const mergeOperatingHours = (
  hours: OperatingHour[]
): Record<string, string> => {
  const mergedHours: Record<string, string> = {};

  hours.forEach((hour) => {
    if (!mergedHours[hour.day]) {
      mergedHours[hour.day] = `${formatTime(hour.open_time)} - ${formatTime(
        hour.close_time
      )}`;
    } else {
      mergedHours[hour.day] += `, ${formatTime(hour.open_time)} - ${formatTime(
        hour.close_time
      )}`;
    }
  });

  return mergedHours;
};

const DesktopOperationalTimes: React.FC<{
  operatingHours: OperatingHour[];
}> = ({ operatingHours }) => {
  const mergedHours = mergeOperatingHours(operatingHours);
  const daysOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div className="operational-times">
      <h2>Operational Times</h2>
      <ul>
        {daysOrder.map((day) => (
          <li key={day}>
            <span>{day}</span>
            <span>{mergedHours[day] || "Closed"}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DesktopOperationalTimes;
