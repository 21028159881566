import AddressAutocomplete from "./AddressAutoComplete";
import { Address } from "./useAddressAutoComplete";

interface IProps {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  form: {
    name: string;
    email: string;
    phone: string;
    wallet_points: string;
  };
  setForm: React.Dispatch<
    React.SetStateAction<{
      name: string;
      avatar: string;
      email: string;
      phone: string;
      wallet_points: string;
    }>
  >;

  address: {
    street: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
  };

  handlePlaceSelected: (place: google.maps.places.PlaceResult) => void;
  handleInputChange: (field: keyof Address, value: string) => void;
  cancelBtn?: boolean;
  onCancel?: () => void;
}

const CustomerProfile = (props: IProps) => {
  const {
    handleSubmit,
    form,
    setForm,
    address,
    handleInputChange,
    handlePlaceSelected,
    cancelBtn = false,
    onCancel,
  } = props;
  const handleChange = (value: string, name: string) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-1 flex-col justify-center p-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Customer Information
        </h2>
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit}>
          <h2 className="text-lg mb-4 font-bold leading-6 text-gray-900">
            Customer Profile
          </h2>
          <div>
            <label
              htmlFor="name"
              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
            >
              Name
              <span className="text-red-600">*</span>
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="name"
                name="name"
                value={form.name}
                required
                autoComplete="name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => handleChange(e.target.value, "name")}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
            >
              Email
              <span className="text-red-600">*</span>
            </label>
            <div className="mt-2">
              <input
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                required
                value={form.email}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => handleChange(e.target.value, "email")}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="phone"
              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
            >
              Phone Number
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                autoComplete="phone"
                value={form.phone}
                disabled
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-70"
                onChange={(e) => handleChange(e.target.value, "phone")}
              />
            </div>
          </div>
          <h2 className="text-lg my-4 font-bold leading-6 text-gray-900">
            Billing Address
          </h2>
          <AddressAutocomplete
            value={address.street}
            onChange={(value: string) => handleInputChange("street", value)}
            onPlaceSelected={handlePlaceSelected}
          />
          <div className="flex space-x-4">
            <div>
              <label
                htmlFor="city"
                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
              >
                City
                <span className="text-red-600">*</span>
              </label>
              <div className="mt-2">
                <input
                  id="city"
                  type="text"
                  name="city"
                  required
                  autoComplete="city"
                  value={address.city}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputChange("city", e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="state"
                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
              >
                State
                <span className="text-red-600">*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="state"
                  name="state"
                  required
                  autoComplete="state"
                  value={address.state}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputChange("state", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex space-x-4">
            <div>
              <label
                htmlFor="country"
                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
              >
                Country
                <span className="text-red-600">*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="country"
                  name="country"
                  required
                  autoComplete="country"
                  value={address.country}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputChange("country", e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="pincode"
                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
              >
                Zip Code
                <span className="text-red-600">*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="pincode"
                  name="pincode"
                  required
                  autoComplete="pincode"
                  value={address.pincode}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputChange("pincode", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="block mt-6 w-full rounded-md bg-red-600 text-white text-lg p-3 font-semibold leading-6 shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Save
            </button>
            {cancelBtn && (
              <button
                type="button"
                onClick={onCancel}
                className="block mt-6 w-full rounded-md bg-gray-400 text-white text-lg p-3 font-semibold leading-6 shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerProfile;
