import { useCustomer } from "@/contexts/CustomerContext";
import { RootState } from "@/store";
import classNames from "@/utils/shared/ClassesUtils";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import WarningBanner from "../ui/banners/WarningBanner";
import Pagination from "../ui/pagination";
import AddressAutocomplete from "./AddressAutoComplete";
import { Branch } from "./CartPage";
import CookieCheck from "./CookieCheck";
import CustomerProfile from "./CustomerProfile";
import Spinner from "./Loader/Spinner";
import { useAddressAutocomplete } from "./useAddressAutoComplete";

interface IOrder {
  order_name: string;
  order_number: string;
  total_price: string;
  tax?: string;
  tip?: string;
  fulfillment_status: string;
  payment_status: string;
  created_at: string;
  delivery_fee?: string;
  subtotal_price: string;
  order_comments?: string;
  surcharge_fee: string | null;
  order_type: string;
  payment_type: string;
  delivery_address?: {
    address: string;
    city: string;
    region: string;
    country: string;
    postal: string;
  };
  order_items: {
    product_id: string;
    product_name: string;
    price_set: string;
    discount_set: string;
    quantity: number;
  }[];
}

const ProfilePage = () => {
  const { login, customer, logout } = useCustomer();
  const branchId = useSelector((state: RootState) => state.foodCart.branchId);
  const [axiosInstance] = useAxiosInstance();
  const [isOpen, setIsOpen] = useState({
    profile: false,
    details: false,
    retry: false,
  });
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [paymentStatus, setPaymentStatus] = useState("Paid");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 5;
  const [error, setError] = useState("");
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const [branch, setBranch] = useState<Branch>();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const tokenInputRef = useRef<HTMLInputElement>(null);

  const [form, setForm] = useState({
    name: customer?.name ?? "",
    avatar: "",
    email: customer?.email ?? "",
    phone: customer?.phone ?? "",
    wallet_points: parseFloat(customer?.wallet_points ?? "0.00").toFixed(2),
  });

  const [payment, setPayment] = useState({
    cvv: "",
    expiry_month: "",
    expiry_year: "",
    phone: customer?.phone ?? "",
    name: customer?.name ?? "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    cvv: "",
    expiry_month: "",
    expiry_year: "",
  });

  useEffect(() => {
    if (!customer) {
      navigate("/store");
    }
  }, [customer, navigate]);

  const { address, setAddress, handlePlaceSelected, handleInputChange } =
    useAddressAutocomplete({
      street: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });

  const {
    address: paymentAddress,
    setAddress: setPaymentAddress,
    handlePlaceSelected: handlePaymentAddress,
    handleInputChange: handlePaymentAddressChange,
  } = useAddressAutocomplete({
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  useEffect(() => {
    if (customer) {
      setAddress({
        street: customer.street ?? "",
        city: customer.city ?? "",
        state: customer.state ?? "",
        country: customer.country ?? "",
        pincode: customer.pincode ?? "",
      });
      setPaymentAddress({
        street:
          (order && order.delivery_address
            ? order.delivery_address.address
            : customer.street) ?? "",
        city:
          (order && order.delivery_address
            ? order.delivery_address.city
            : customer.city) ?? "",
        state:
          (order && order.delivery_address
            ? order.delivery_address.region
            : customer.state) ?? "",
        country:
          (order && order.delivery_address
            ? order.delivery_address.country
            : customer.country) ?? "",
        pincode:
          (order && order.delivery_address
            ? order.delivery_address.postal
            : customer.pincode) ?? "",
      });
    }
  }, [customer, order, setAddress, setPaymentAddress]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchOrders = async () => {
      if (axiosInstance == null) return;
      setLoading(true);
      try {
        const businessName = localStorage.getItem("subdomain") || "";
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/${branchId}?business_name=${businessName}`;
        const response = await axiosInstance.get(apiUrl);
        setBranch(response.data);
        const { data } = await axiosInstance.get("/api/orders", {
          params: {
            customer_name: customer?.name,
            page_size: limit,
            payment_status: paymentStatus,
            page: page,
            branch: branchId,
            pageSize: limit,
          },
        });
        const { data: points } = await axiosInstance.get(
          "/api/fetch-customer-points",
          {
            params: {
              phone: customer?.phone,
              branch: branchId,
            },
          }
        );
        setOrders(data.orders);
        setTotalCount(data.total_orders);
        setForm((prev) => ({
          ...prev,
          wallet_points: parseFloat(points.points).toFixed(2),
        }));
        toast.success("Orders fetched successfully");
      } catch (error: any) {
        if (!branchId) {
          toast.error("Please select a branch");
        } else if (
          axiosInstance != null &&
          error.name !== "AbortError" &&
          error.name !== "CanceledError"
        ) {
          console.error("Failed to fetch orders:", error);
          toast.error("Failed to fetch orders");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
    return () => controller.abort();
  }, [
    axiosInstance,
    branchId,
    customer?.name,
    customer?.phone,
    page,
    paymentStatus,
  ]);

  useEffect(() => {
    const handleTokenMessage = (event: MessageEvent) => {
      try {
        const tokenData = JSON.parse(event.data);
        if (tokenData.message) {
          tokenInputRef.current!.value = tokenData.message;
          setToken(tokenData.message);
        }
      } catch (error) {
        toast.error("Error parsing token data");
        console.error("Error :", error);
      }
    };

    window.addEventListener("message", handleTokenMessage, false);
    return () => {
      window.removeEventListener("message", handleTokenMessage, false);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = {
        ...form,
        ...address,
      };
      const res = await axiosInstance.post("/api/customer-profile", data);
      if (res.status === 201) {
        toast.success("Profile updated successfully");
        login(data);
        setIsOpen((p) => ({ ...p, profile: false }));
      }
    } catch (error) {
      toast.error("Failed to update profile");
      console.error(error);
    }
  };
  const handlePageChange = (page: number) => setPage(page);
  const handlePaymentStatus = (status: string) => {
    setPaymentStatus(status);
    setPage(1);
  };

  const validateForm = () => {
    const newErrors = {
      name: payment.name.trim() === "" ? "Cardholder name is required" : "",
      phone: payment.phone.trim() === "" ? "Phone number is required" : "",
      cvv: payment.cvv.length !== 3 ? "CVV must be 3 digits" : "",
      expiry_month: !/^(0[1-9]|1[0-2])$/.test(payment.expiry_month)
        ? "Invalid month"
        : "",
      expiry_year: !/^\d{4}$/.test(payment.expiry_year) ? "Invalid year" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleChange = (name: string, value: string) => {
    setPayment((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const submitPayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!token) {
      setError("Invalid Card Number");
      setLoading(false);
      return;
    }
    if (!validateForm()) {
      setError("Please fix the errors in the form before submitting.");
      setLoading(false);
      return;
    }

    const body = {
      ...payment,
      address: paymentAddress?.street,
      city: paymentAddress?.city,
      region: paymentAddress?.state,
      country: paymentAddress?.country,
      postal: paymentAddress?.pincode,
      order_name: order?.order_name,
      token: token,
      order_type: order?.order_type,
      order_items: order?.order_items,
    };

    try {
      const response = await axiosInstance.post("/api/retry-payment", body);
      if (response.status === 201) {
        toast.success("Order created successfully");
        setIsOpen((p) => ({ ...p, retry: false }));
        navigate("/order-confirmation");
      }
    } catch (error: any) {
      if (error.response.status === 402) {
        toast.error("Payment failed. Please try again.");
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      setError("Payment Denied");
    } finally {
      setLoading(false);
    }
  };

  const renderOrders = () => {
    if (orders.length === 0) {
      return (
        <p className="text-gray-500 text-center">
          No {paymentStatus} orders found
        </p>
      );
    }
    return (
      <>
        {orders.map((order) => (
          <div
            key={order.order_name}
            onClick={() => {
              setOrder(order);
              setIsOpen((p) => ({ ...p, details: true }));
            }}
            className="flex justify-between items-center text-sm md:text-base"
          >
            <div
              className={classNames(
                "grid grid-cols-3 justify-center w-full border-l-8 p-4 gap-2 rounded-lg shadow-md mb-4",
                order.payment_status === "Paid"
                  ? "border-green-500"
                  : order.payment_status === "Unpaid"
                  ? "border-gray-500"
                  : "border-red-500"
              )}
            >
              <div className="col-span-2 flex-1">
                <div className="flex justify-between items-start text-primary">
                  <div>
                    <h3 className="font-bold text-lg">{order.order_name}</h3>
                    <div className="flex space-x-5">
                      <span>{order.order_items.length} items</span>
                      <p>${order.total_price}</p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <span>{order.order_type}</span>
                    <span>
                      {new Date(order.created_at).toDateString()}{" "}
                      {new Date(order.created_at)
                        .toTimeString()
                        .substring(0, 5)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center space-y-4">
                <button
                  onClick={() => {
                    setOrder(order);
                    setIsOpen((p) => ({ ...p, details: true }));
                  }}
                  className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 rounded-md"
                >
                  Details
                </button>
                {/* {paymentStatus === "Unpaid" && (
                  <button
                    onClick={() => {
                      setOrder(order);
                      setIsOpen((p) => ({ ...p, retry: true }));
                    }}
                    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 rounded-md"
                  >
                    Retry
                  </button>
                )} */}
              </div>
            </div>
          </div>
        ))}
        <Pagination
          className="w-full mt-8"
          cPage={1}
          data={{ totalCount: totalCount }}
          defaultPerPage={limit}
          onChange={handlePageChange}
        />
      </>
    );
  };

  return (
    <div>
      <Transition appear show={isOpen.profile} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen((p) => ({ ...p, profile: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <CustomerProfile
                    handleSubmit={handleSubmit}
                    form={form}
                    setForm={setForm}
                    address={address}
                    handlePlaceSelected={handlePlaceSelected}
                    handleInputChange={handleInputChange}
                    cancelBtn
                    onCancel={() =>
                      setIsOpen((p) => ({ ...p, profile: false }))
                    }
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen.details} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen((p) => ({ ...p, details: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-primary text-left align-middle shadow-xl transition-all">
                  <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
                    <h2 className="text-xl font-bold text-center">
                      #{order?.order_name}
                    </h2>

                    <div className="mb-8 border border-gray-300 rounded-md p-4 mt-4 text-sm">
                      <h3 className="text-lg font-medium mb-2 text-center">
                        {(order?.order_type || "").toUpperCase()}
                      </h3>

                      <div className="flex justify-between mt-4">
                        <span>{order?.payment_type}</span>

                        <span>
                          {order?.created_at.substring(0, 10)}{" "}
                          {order?.created_at.substring(11, 19)}
                        </span>
                      </div>

                      <ul className="my-4 border-t border-b border-dashed border-gray-500 py-4">
                        {order?.order_items.map((item) => (
                          <li
                            key={item.product_id}
                            className="grid grid-cols-2 items-center"
                          >
                            <div className="grid grid-cols-3 justify-start">
                              <span className="text-left">{item.quantity}</span>
                              <span className="col-span-2">
                                {item.product_name}
                              </span>
                            </div>
                            <span className="text-right">
                              ${item.price_set}
                            </span>
                          </li>
                        ))}
                      </ul>

                      <div className="mb-2">
                        <h4 className="font-medium">Comments</h4>
                        <p className="ml-3 mt-3 text-gray-600">
                          {order?.order_comments}
                        </p>
                      </div>

                      <div className="border-t border-b border-gray-500 border-dashed my-4 py-4">
                        <div className="flex justify-between">
                          <span className="font-medium">Subtotal</span>
                          <span className="text-gray-700">
                            ${order?.subtotal_price}
                          </span>
                        </div>

                        <div className="flex justify-between">
                          <span className="font-medium">Tax:</span>
                          <span>${order?.tax}</span>
                        </div>

                        <div className="flex justify-between">
                          <span className="font-medium">Tip:</span>
                          <span>$ {order?.tip}</span>
                        </div>

                        <div className="flex justify-between">
                          <span className="font-medium">Service Fee:</span>
                          <span>$ {order?.surcharge_fee ?? "0.00"}</span>
                        </div>

                        <div className="flex justify-between mt-2">
                          <span className="font-medium">Total:</span>
                          <span>$ {order?.total_price}</span>
                        </div>
                      </div>
                      <div className="mt-4 text-center">
                        <span className="text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                          {(order?.payment_status || "").toUpperCase()}
                        </span>
                      </div>
                    </div>

                    <button
                      className="mt-4 w-full bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        setIsOpen((p) => ({ ...p, details: false }))
                      }
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen.retry} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen((p) => ({ ...p, profile: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-1 flex-col justify-center p-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                      <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Retry Payment
                      </h2>
                    </div>
                    <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                      <form onSubmit={submitPayment}>
                        <h2 className="text-lg mb-4 font-bold leading-6 text-gray-900">
                          Order Details
                        </h2>
                        <div className="w-full flex flex-col justify-between text-primary">
                          <div className="flex justify-between">
                            <span>
                              Subtotal ({order?.order_items.length} items)
                            </span>
                            <span>${order?.subtotal_price}</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Tax (8.25%)</span>
                            <span>${order?.tax}</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Tip</span>
                            <span>${order?.tip}</span>
                          </div>
                          <div className="flex justify-between">
                            <span>Service Fee</span>
                            <span>${order?.surcharge_fee ?? "0.00"}</span>
                          </div>
                          {order?.order_type === "Delivery" && (
                            <div className="flex justify-between">
                              <span>Delivery Fees</span>
                              <span>${order?.delivery_fee}</span>
                            </div>
                          )}
                          <div className="flex justify-between">
                            <span>Total</span>
                            <span>${order?.total_price}</span>
                          </div>
                        </div>
                        {error && (
                          <WarningBanner
                            title="Payment Error"
                            text={error}
                            className="mt-4"
                          />
                        )}
                        <h2 className="text-lg my-4 font-bold leading-6 text-gray-900">
                          Card Details
                        </h2>
                        <div>
                          <label
                            htmlFor="card_number"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Card Number
                            <span className="text-red-600">*</span>
                          </label>
                          <CookieCheck />
                          <iframe
                            title="Token Frame"
                            ref={iframeRef}
                            id="tokenFrame"
                            name="tokenFrame"
                            height={48}
                            src="https://fts.cardconnect.com/itoke/ajax-tokenizer.html?css=%2Eerror%7Bcolor%3A%20red%3B%7Dinput%7Bwidth%3A285px%3B%7Dinput%7Bheight%3A25px%3B%7D"
                            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          />
                          <input
                            type="hidden"
                            ref={tokenInputRef}
                            name="token"
                            id="tokenInput"
                          />
                        </div>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
                          <div>
                            <label
                              htmlFor="name"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Cardholder Name
                              <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={payment.name}
                                required
                                autoComplete="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleChange("name", e.target.value)
                                }
                              />
                              {errors.name && (
                                <div className="text-red-500 text-sm">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="phone"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Phone Number
                            </label>
                            <div className="mt-2">
                              <input
                                type="tel"
                                id="phone"
                                name="phone"
                                required
                                autoComplete="phone"
                                value={payment.phone}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-70"
                                onChange={(e) =>
                                  handleChange("phone", e.target.value)
                                }
                              />
                              {errors.phone && (
                                <div className="text-red-500 text-sm">
                                  {errors.phone}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="md:col-span-2 flex flex-col md:flex-row md:space-x-3">
                            <div>
                              <label
                                htmlFor="cvv"
                                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                CVV
                                <span className="text-red-600">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  maxLength={4}
                                  id="cvv"
                                  name="cvv"
                                  value={payment.cvv}
                                  required
                                  autoComplete="cvv"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) =>
                                    handleChange("cvv", e.target.value)
                                  }
                                />
                                {errors.cvv && (
                                  <div className="text-red-500 text-sm">
                                    {errors.cvv}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="expiry_month"
                                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Expiry Month
                                <span className="text-red-600">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  placeholder="MM"
                                  maxLength={2}
                                  id="expiry_month"
                                  name="expiry_month"
                                  autoComplete="expiry_month"
                                  value={payment.expiry_month}
                                  min={1}
                                  max={12}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) =>
                                    handleChange("expiry_month", e.target.value)
                                  }
                                />
                                {errors.expiry_month && (
                                  <div className="text-red-500 text-sm">
                                    {errors.expiry_month}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="expiry_year"
                                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Expiry Year
                                <span className="text-red-600">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="tel"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  placeholder="YYYY"
                                  maxLength={4}
                                  id="expiry_year"
                                  name="expiry_year"
                                  autoComplete="expiry_year"
                                  value={payment.expiry_year}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(e) =>
                                    handleChange("expiry_year", e.target.value)
                                  }
                                />
                                {errors.expiry_year && (
                                  <div className="text-red-500 text-sm">
                                    {errors.expiry_year}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="text-lg my-4 font-bold leading-6 text-gray-900">
                          Billing Address
                        </h2>
                        <AddressAutocomplete
                          value={paymentAddress.street}
                          onChange={(value: string) =>
                            handlePaymentAddressChange("street", value)
                          }
                          onPlaceSelected={handlePaymentAddress}
                        />
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          <div>
                            <label
                              htmlFor="city"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              City
                              <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                id="city"
                                type="text"
                                name="city"
                                required
                                autoComplete="city"
                                value={address.city}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleInputChange("city", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="state"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              State
                              <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="state"
                                name="state"
                                required
                                autoComplete="state"
                                value={address.state}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleInputChange("state", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          <div>
                            <label
                              htmlFor="country"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Country
                              <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="country"
                                name="country"
                                required
                                autoComplete="country"
                                value={address.country}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleInputChange("country", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="pincode"
                              className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Pincode
                              <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="pincode"
                                name="pincode"
                                required
                                autoComplete="pincode"
                                value={address.pincode}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  handleInputChange("pincode", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="block mt-6 w-full rounded-md bg-red-600 text-white text-lg p-3 font-semibold leading-6 shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          >
                            Retry Payment
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              setIsOpen((p) => ({ ...p, retry: false }))
                            }
                            className="block mt-6 w-full rounded-md bg-gray-400 text-white text-lg p-3 font-semibold leading-6 shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Spinner />
        </div>
      )}
      <div className="flex flex-col items-center space-y-10 p-5 md:p-10">
        <div className="flex flex-col space-y-5">
          <div className="flex items-center justify-between bg-white mb-4">
            <div className="flex gap-2">
              <Link to="/store" className="text-black hover:underline">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </Link>
              <h1 className="text-xl font-semibold">Customer Profile</h1>
            </div>
            <button
              className="text-white bg-black text-sm p-2 rounded-md font-semibold"
              onClick={() => setIsOpen((p) => ({ ...p, profile: true }))}
            >
              Edit Profile
            </button>
          </div>
          <div className="grid grid-cols-2 items-center gap-5">
            <div>
              <span className="text-lg text-[#777980]">Customer Name</span>
              <h1 className="ml-3 font-bold">{customer?.name}</h1>
            </div>
            {branch?.loyalty_program_enabled && (
              <div>
                <span className="text-lg text-[#777980]">Points</span>
                <h1 className="ml-3 font-bold">
                  {form?.wallet_points ? (
                    <span>{(form?.wallet_points).split(".")[0]} points</span>
                  ) : (
                    "0 points"
                  )}
                </h1>
              </div>
            )}
            <div>
              <span className="text-lg text-[#777980]">Phone Number</span>
              <h1 className="ml-3 font-bold">{customer?.phone}</h1>
            </div>
            <div className="col-span-2">
              <span className="text-lg text-[#777980]">Email Address</span>
              <h1 className="ml-3 font-bold">
                {customer?.email ? customer?.email : "NA"}
              </h1>
            </div>
          </div>
          <div>
            <span className="text-lg text-[#777980]">Billing Address</span>
            <h1 className="ml-3 font-bold">
              {customer?.street}, {customer?.city}, {customer?.state},{" "}
              {customer?.country} - {customer?.pincode}
            </h1>
          </div>
          <h2 className="text-lg lg:text-2xl font-bold mb-4">Order History</h2>
          <div className="flex flex-col justify-center gap-8">
            <Tab.Group>
              <Tab.List className="grid grid-cols-2 items-center space-x-2 rounded-md border border-gray-300 p-1">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                      selected
                        ? "bg-red-500 text-white shadow-md outline-none"
                        : "bg-white text-primary hover:bg-red-50"
                    )
                  }
                  onClick={() => handlePaymentStatus("Paid")}
                >
                  Paid Orders
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                      selected
                        ? "bg-red-500 text-white shadow-md outline-none"
                        : "bg-white text-primary hover:bg-red-50"
                    )
                  }
                  onClick={() => handlePaymentStatus("Unpaid")}
                >
                  Draft Orders
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>{renderOrders()}</Tab.Panel>
                <Tab.Panel>{renderOrders()}</Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="flex flex-col lg:flex-row lg:space-x-5">
              <Link
                to="/store"
                className="block text-center mt-6 w-full rounded-md bg-red-600 text-white text-lg p-3 font-semibold leading-6 shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                Back to Store
              </Link>
              <button
                type="button"
                onClick={logout}
                className="block mt-6 w-full rounded-md bg-gray-400 text-white text-lg p-3 font-semibold leading-6 shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
