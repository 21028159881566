import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import React, { CSSProperties, useEffect, useState } from "react";
import toast from "react-hot-toast";

const styles: Record<string, CSSProperties> = {
  container: {
    marginBottom: "32px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#1f2937",
  },
  subtitle: {
    fontSize: "1.3rem",
    color: "#4b5563",
  },
};

const CustomerLoyalty: React.FC = () => {
  const { user, login } = useUser();
  const [axiosInstance] = useAxiosInstance();
  const [form, setForm] = useState({
    points_conversion_rate: 0,
    points_to_currency_multipler: 0,
    min_points_to_redeem: 0,
    max_points_to_redeem: 0,
    loyalty_program_enabled: true,
    min_order_to_redeem_points: 0,
  });

  useEffect(() => {
    if (!user || !user.selectedBranch) return;
    setForm({
      points_conversion_rate: parseFloat(
        parseFloat(user.selectedBranch.points_conversion_rate ?? "1").toFixed(2)
      ),
      points_to_currency_multipler: parseFloat(
        parseFloat(
          user.selectedBranch.points_to_currency_multiplier ?? "1"
        ).toFixed(2)
      ),
      min_points_to_redeem: parseInt(
        user.selectedBranch.min_points_to_redeem ?? "1"
      ),
      max_points_to_redeem: parseInt(
        user.selectedBranch.max_points_to_redeem ?? "10000"
      ),
      loyalty_program_enabled: user.selectedBranch.loyalty_program_enabled,
      min_order_to_redeem_points: parseInt(
        user.selectedBranch.min_order_to_redeem_points ?? "1"
      ),
    });
  }, [user]);

  const handleChange = (name: string, value: number) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (form.min_points_to_redeem > form.max_points_to_redeem) {
        toast.error("Min points to redeem should be less than max points");
        return;
      }
      if (axiosInstance) {
        const branchId = user?.selectedBranch?.id;
        const apiUrl = `${process.env.REACT_APP_REDBIRDPOSBE_BRANCHES}${branchId}/loyalty_program/`;
        if (!apiUrl) {
          console.error("API URL for branches is not defined in .env");
          return;
        }
        const res = await axiosInstance.patch(apiUrl, form);
        if (res.status === 200) {
          toast.success("Loyalty program updated successfully");
          const storedUserData = localStorage.getItem("user_data");
          const userData = storedUserData ? JSON.parse(storedUserData) : null;
          const updatedBranch = userData.selectedBranch;
          if (updatedBranch) {
            updatedBranch.points_to_currency_multiplier =
              form.points_to_currency_multipler;
            updatedBranch.points_conversion_rate = form.points_conversion_rate;
            updatedBranch.min_points_to_redeem = form.min_points_to_redeem;
            updatedBranch.max_points_to_redeem = form.max_points_to_redeem;
            updatedBranch.loyalty_program_enabled =
              form.loyalty_program_enabled;
            updatedBranch.min_order_to_redeem_points =
              form.min_order_to_redeem_points;
            localStorage.setItem("user_data", JSON.stringify(userData));
            login(userData);
          }
        }
      }
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("Error updating loyalty program");
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Customer Loyalty</h1>
      <p style={styles.subtitle}>
        Set the rules for your customer loyalty program
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <div className="mb-4">
            <h2 className="text-lg font-semibold">
              Loyalty Program Configuration
            </h2>
            <div>
              <p className="text-sm text-gray-500">
                The loyalty program allows customers to earn points for each
                purchase. The points can be redeemed for discounts on future
                purchases.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full px-2 mb-4 items-center">
              <input
                type="checkbox"
                id="loyalty_program_enabled"
                checked={!form.loyalty_program_enabled}
                onChange={(e) =>
                  setForm({
                    ...form,
                    loyalty_program_enabled: !e.target.checked,
                  })
                }
              />
              <label className="ml-2 text-gray-700 text-sm font-bold">
                Disable loyalty program
              </label>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="points"
              >
                Points Multiplier
                <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                id="points_to_currency_multipler"
                name="points_to_currency_multipler"
                required
                placeholder="Enter the point multiplier (e.g. 1.5)"
                value={form.points_to_currency_multipler}
                disabled={!form.loyalty_program_enabled}
                step={0.5}
                onChange={(e) =>
                  handleChange(
                    "points_to_currency_multipler",
                    parseFloat(e.target.value)
                  )
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring disabled:opacity-50"
                onBlur={(e) =>
                  setForm({
                    ...form,
                    points_to_currency_multipler: parseFloat(
                      parseFloat(e.target.value).toFixed(2)
                    ),
                  })
                }
              />
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="value"
              >
                Points Conversion Rate
                <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  id="points_conversion_rate"
                  name="points_conversion_rate"
                  required
                  placeholder="Enter 1 point value in dollars (e.g. 0.01)"
                  value={form.points_conversion_rate}
                  step={0.01}
                  disabled={!form.loyalty_program_enabled}
                  onChange={(e) =>
                    handleChange(
                      "points_conversion_rate",
                      parseFloat(e.target.value)
                    )
                  }
                  className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring disabled:opacity-50"
                  onBlur={(e) =>
                    setForm({
                      ...form,
                      points_conversion_rate: parseFloat(
                        parseFloat(e.target.value).toFixed(2)
                      ),
                    })
                  }
                />
                <span className="ml-2 text-gray-500">USD</span>
              </div>
            </div>
            <div className="w-full px-2 mb-4">
              <label
                htmlFor="steps"
                className="block mb-2 text-sm font-medium text-primary"
              >
                Points user can redeem for discount on a single order
              </label>
              <div className="relative mb-8">
                <div className="flex items-center">
                  <label
                    htmlFor="steps"
                    className="block mb-2 text-sm font-medium text-primary"
                  >
                    Min points
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="min_points_to_redeem"
                    value={form.min_points_to_redeem}
                    type="number"
                    disabled={!form.loyalty_program_enabled}
                    onChange={(e) =>
                      handleChange(
                        "min_points_to_redeem",
                        parseInt(e.target.value)
                      )
                    }
                    className="ml-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring disabled:opacity-50"
                  />
                </div>
                <input
                  id="min_points_to_redeem"
                  type="range"
                  value={form.min_points_to_redeem}
                  disabled={!form.loyalty_program_enabled}
                  onChange={(e) =>
                    handleChange(
                      "min_points_to_redeem",
                      parseInt(e.target.value)
                    )
                  }
                  step="1"
                  min="1"
                  max="10000"
                  className="w-full h-2 bg-red-500 rounded-lg appearance-none cursor-pointer disabled:opacity-50"
                />
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">
                  1
                </span>
                <span className="ml-2 text-sm text-gray-500 dark:text-gray-400 absolute start-1/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                  2500
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                  5000
                </span>
                <span className="-ml-1 text-sm text-gray-500 dark:text-gray-400 absolute start-3/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                  7500
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">
                  10000
                </span>
              </div>
              <div className="relative mb-4">
                <div className="flex items-center">
                  <label
                    htmlFor="steps"
                    className="block mb-2 text-sm font-medium text-primary"
                  >
                    Max points
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="max_points_to_redeem"
                    value={form.max_points_to_redeem}
                    type="number"
                    disabled={!form.loyalty_program_enabled}
                    onChange={(e) =>
                      handleChange(
                        "max_points_to_redeem",
                        parseInt(e.target.value)
                      )
                    }
                    className="ml-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring disabled:opacity-50"
                  />
                </div>
                <input
                  id="max_points_to_redeem"
                  type="range"
                  disabled={!form.loyalty_program_enabled}
                  onChange={(e) =>
                    handleChange(
                      "max_points_to_redeem",
                      parseInt(e.target.value)
                    )
                  }
                  value={form.max_points_to_redeem}
                  step="1"
                  min="1"
                  max="10000"
                  className="w-full h-2 bg-red-500 rounded-lg appearance-none cursor-pointer disabled:opacity-50"
                />
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">
                  1
                </span>
                <span className="ml-2 text-sm text-gray-500 dark:text-gray-400 absolute start-1/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                  2500
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                  5000
                </span>
                <span className="-ml-1 text-sm text-gray-500 dark:text-gray-400 absolute start-3/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
                  7500
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">
                  10000
                </span>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="points"
              >
                Min Order value to redeem loyalty points
                <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                id="min_order_to_redeem_points"
                name="min_order_to_redeem_points"
                required
                placeholder="Enter the minimum order value to redeem points"
                value={form.min_order_to_redeem_points}
                disabled={!form.loyalty_program_enabled}
                step={0.5}
                onChange={(e) =>
                  handleChange(
                    "min_order_to_redeem_points",
                    parseFloat(e.target.value)
                  )
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring disabled:opacity-50"
                onBlur={(e) =>
                  setForm({
                    ...form,
                    min_order_to_redeem_points: parseFloat(
                      parseFloat(e.target.value).toFixed(2)
                    ),
                  })
                }
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-red-500 text-white rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CustomerLoyalty;
