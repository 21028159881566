import { RootState } from "@/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface Option {
  id: number;
  name: string;
  description: string;
  surcharge: string;
  created_at: string;
}
interface OptionList {
  id: number;
  name: string;
  options: Option[];
  required: boolean;
  option_type: string;
  max_selections: number | null;
}
interface Product {
  id: number;
  name: string;
  unit_price: string;
  description: string;
  image: string;
  product_option_lists: OptionList[];
  is_upsell: boolean;
}

interface SelectedOption {
  quantity: number;
  selected: boolean;
  surcharge?: number;
}

interface CartItem extends Product {
  quantity: number;
  selected_options: { [key: string]: SelectedOption };
  uniqueId: string; // Add a uniqueId to each cart item
}

interface CartState {
  items: CartItem[];
  branchId: string | null;
  serviceFee: number;
  taxRate: number;
}

const initialState: CartState = {
  items: [],
  branchId: null,
  serviceFee: 0,
  taxRate: 0,
};

const generateUniqueId = (item: CartItem) => {
  const optionsString = Object.entries(item.selected_options)
    .map(([key, option]) => `${key}-${option.quantity}-${option.surcharge}`)
    .join("|");
  return `${item.id}-${optionsString}`;
};

const cartSlice = createSlice({
  name: "foodCart",
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<CartItem>) => {
      const newItem = action.payload;
      const uniqueId = generateUniqueId(newItem);
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
      } else {
        state.items.push({ ...newItem, uniqueId });
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(
        (item) => item.uniqueId !== action.payload
      );
    },
    updateItemQuantity: (
      state,
      action: PayloadAction<{
        itemId: string;
        quantity: number;
      }>
    ) => {
      const { itemId, quantity } = action.payload;
      const item = state.items.find((item) => item.uniqueId === itemId);
      if (item) {
        item.quantity = quantity;
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
    setBranchId: (state, action: PayloadAction<string | null>) => {
      state.branchId = action.payload;
    },
    setServiceFee: (state, action: PayloadAction<number>) => {
      state.serviceFee = action.payload;
    },
    setTaxRate: (state, action: PayloadAction<number>) => {
      state.taxRate = action.payload;
    },
  },
});

export const {
  addItem,
  removeItem,
  updateItemQuantity,
  clearCart,
  setBranchId,
  setServiceFee,
  setTaxRate,
} = cartSlice.actions;

export const selectCartItemCount = (state: RootState) => {
  return state.foodCart.items.reduce((total, item) => total + item.quantity, 0);
};
export default cartSlice.reducer;
