import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "@/assets/img/logo-white.png";

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex flex-col md:flex-row justify-between items-center">
        <div className="flex-shrink-0 mb-4 md:mb-0">
          <Link to="/">
            <img src={FooterLogo} alt="Redbird Logo" className="h-8" />
          </Link>
        </div>
        <p className="text-left mb-4 md:mb-0">
          Redbird Copyright 2024, All Rights Reserved.
        </p>
        <div className="flex justify-center space-x-4">
          <Link to="/" className="hover:underline">
            Privacy Policy
          </Link>
          <Link to="/" className="hover:underline">
            Terms
          </Link>
          <Link to="/" className="hover:underline">
            Pricing
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
