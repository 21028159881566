import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Logo from "@/components/front/Logo";
import orderImage from "@/assets/img/order-confirmation.png";

const OrderConfirmation: React.FC = () => {
  const location = useLocation();
  const history = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const orderNumber = queryParams.get("order_number") || "N/A";
  const handleClose = () => {
    history("/store");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-4">
          <Link to="/store">
            <svg
              className="w-6 h-6 text-gray-700"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Link>
        </div>
        <h1 className="text-2xl font-bold mb-4">Enjoy your order</h1>
        <p className="text-gray-700 mb-4">
          Thanks for ordering with Redbird
          <br />
          Your order number is: <span className="font-bold">{orderNumber}</span>
          <br />
          We have sent a confirmation text to your phone number.
        </p>
        <img
          src={orderImage}
          alt="Order"
          className="w-full h-64 object-cover rounded-lg mb-4"
        />
        <div className="flex justify-center mb-4">
          <Logo className="h-12 w-auto" />
        </div>
        <button
          onClick={handleClose}
          className="bg-red-500 text-white w-full py-3 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default OrderConfirmation;
