import React, { useState, useEffect } from "react";
import ManagementLayout from "@/views/back/ManagementLayout";
import Dashboard from "@/views/back/management/Dashboard";
import AddBranch from "@/views/back/management/components/dashboard/AddBranch";
import Category from "@/views/back/management/components/menu/Category";
import AddCategory from "@/views/back/management/components/menu/AddCategory";
import EditCategory from "@/views/back/management/components/menu/EditCategory";
import Upsell from "@/views/back/management/components/menu/Upsell";
import AdjustCategoryRankings from "@/views/back/management/components/menu/AdjustCategoryRankings";
import Product from "@/views/back/management/components/menu/Product";
import AddProduct from "@/views/back/management/components/menu/AddProduct";
import EditProduct from "@/views/back/management/components/menu/EditProduct";
import Option from "@/views/back/management/components/menu/Option";
import AddOption from "@/views/back/management/components/menu/AddOption";
import EditOption from "@/views/back/management/components/menu/EditOption";
import OptionList from "@/views/back/management/components/menu/OptionList";
import AddOptionList from "@/views/back/management/components/menu/AddOptionList";
import EditOptionList from "@/views/back/management/components/menu/EditOptionList";
import Promotion from "@/views/back/management/components/menu/Promotion";
import EditPromotion from "@/views/back/management/components/menu/EditPromotion";
import AddPromotion from "@/views/back/management/components/menu/AddPromotion";
import Orders from "@/views/back/management/Orders";
import Report from "@/views/back/management/components/report/Report";
import Printer from "@/views/back/management/components/Printer/Printer";
import EditPrinter from "@/views/back/management/components/Printer/EditPrinter";
import SetupPrinter from "./management/components/Printer/SetupPrinter";
import CustomerLoyalty from "@/views/back/management/components/customer/CustomerLoyalty";
import CustomerInformation from "@/views/back/management/components/customer/CustomerTable";
import AddCustomer from "@/views/back/management/components/customer/AddCustomer";
import Expense from "@/views/back/management/Expense";
import AddExpense from "@/views/back/management/components/expense/AddExpense";
import Employee from "@/views/back/management/components/employee/EmployeeTable";
import AddEmployee from "@/views/back/management/components/employee/AddEmployee";
import Devices from "@/views/back/management/components/business/DevicesTable";
import BusinessInformation from "./management/components/business/BusinessInformation";
import AddDevice from "@/views/back/management/components/business/AddDevice";
import EditDevice from "@/views/back/management/components/business/EditDevice";
import Support from "@/views/back/management/Support";
import Setting from "@/views/back/management/Setting";
import { initializeServiceWorker } from "../../utils/pushNotification";

const Management: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<string>("dashboard");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    initializeServiceWorker("/user/management");
  }, []);

  const onMenuItemClick = (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => {
    setActiveComponent(componentName);
    setSuccessMessage(successMessage || "");
    setSelectedItemId(selectedItemId || null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "dashboard":
        return (
          <Dashboard
            onMenuItemClick={onMenuItemClick}
            isSidebarOpen={isSidebarOpen}
          />
        );
      case "addBranch":
        return <AddBranch onMenuItemClick={onMenuItemClick} />;
      case "category":
        return (
          <Category
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "addCategory":
        return (
          <AddCategory
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
          />
        );
      case "editCategory":
        return (
          <EditCategory
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
            categoryId={selectedItemId}
          />
        );
      case "adjustCategoryRanking":
        return (
          <AdjustCategoryRankings
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "product":
        return (
          <Product
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "addProduct":
        return (
          <AddProduct
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
          />
        );
      case "editProduct":
        return (
          <EditProduct
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
            productId={selectedItemId}
          />
        );
      case "option":
        return (
          <Option
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "upsell":
        return (
          <Upsell
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "addOption":
        return (
          <AddOption
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
          />
        );
      case "optionList":
        return (
          <OptionList
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "addOptionList":
        return (
          <AddOptionList
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
          />
        );
      case "editOption":
        return (
          <EditOption
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
            optionId={selectedItemId}
          />
        );
      case "editOptionList":
        return (
          <EditOptionList
            onMenuItemClick={onMenuItemClick}
            optionListId={selectedItemId}
          />
        );
      case "promotion":
        return (
          <Promotion
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "addPromotion":
        return (
          <AddPromotion
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
          />
        );
      case "editPromotion":
        return (
          <EditPromotion
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
            promotionId={selectedItemId}
          />
        );
      case "orders":
        return <Orders />;
      case "report":
        return <Report />;
      case "printer":
        return (
          <Printer
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "setupPrinter":
        return <SetupPrinter onMenuItemClick={onMenuItemClick} />;
      case "editPrinter":
        return (
          <EditPrinter
            onMenuItemClick={onMenuItemClick}
            printerId={selectedItemId}
          />
        );
      case "customerloyalty":
        return <CustomerLoyalty />;
      case "customerinformation":
        return <CustomerInformation onMenuItemClick={onMenuItemClick} />;
      case "addCustomer":
        return <AddCustomer onMenuItemClick={onMenuItemClick} />;
      case "expense":
        return <Expense onMenuItemClick={onMenuItemClick} />;
      case "addExpense":
        return <AddExpense onMenuItemClick={onMenuItemClick} />;
      case "employee":
        return <Employee onMenuItemClick={onMenuItemClick} />;
      case "addEmployee":
        return <AddEmployee onMenuItemClick={onMenuItemClick} />;
      case "devices":
        return (
          <Devices
            onMenuItemClick={onMenuItemClick}
            successMessage={successMessage}
          />
        );
      case "addDevice":
        return (
          <AddDevice
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
          />
        );
      case "editDevice":
        return (
          <EditDevice
            onMenuItemClick={onMenuItemClick}
            setSuccessMessage={setSuccessMessage}
            deviceId={selectedItemId}
          />
        );
      case "businessInformation":
        return <BusinessInformation />;
      case "support":
        return <Support />;
      case "setting":
        return <Setting />;
      default:
        return (
          <Dashboard
            onMenuItemClick={onMenuItemClick}
            isSidebarOpen={isSidebarOpen}
          />
        );
    }
  };

  return (
    <ManagementLayout
      onMenuItemClick={onMenuItemClick}
      isSidebarOpen={isSidebarOpen}
      toggleSidebar={toggleSidebar}
    >
      {renderComponent()}
    </ManagementLayout>
  );
};

export default Management;
