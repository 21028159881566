import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWeixin } from "@fortawesome/free-brands-svg-icons";

const Support = () => {
  return (
    <div className="container mx-auto px-4 py-2">
      <h2 className="text-2xl font-semibold mb-4">Support</h2>

      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Contact Us</h3>
        <p className="mb-4">
          We're here to help! Reach out to us through any of the following
          methods:
        </p>

        <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faWeixin}
              className="text-2xl text-green-500 mr-3"
            />
            <div>
              <p className="font-semibold">WeChat</p>
              <p>ID: MichaelChan23</p>
            </div>
          </div>

          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="text-2xl text-blue-500 mr-3"
            />
            <div>
              <p className="font-semibold">Email</p>
              <a
                href="mailto:redbirdcorptx@gmail.com"
                className="text-blue-600 hover:underline"
              >
                redbirdcorptx@gmail.com
              </a>
            </div>
          </div>

          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faPhone}
              className="text-2xl text-red-500 mr-3"
            />
            <div>
              <p className="font-semibold">Phone</p>
              <a
                href="tel:9452962608"
                className="text-blue-600 hover:underline"
              >
                (945) 296-2608
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Support Hours</h3>
        <p>
          Our support team is available Monday through Friday, 9:00 AM to 9:00
          PM (Central Time).
        </p>
      </div>

      {/* <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-2">FAQs</h3>
        <p>
          Check out our frequently asked questions for quick answers to common
          inquiries.
        </p>
        <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          View FAQs
        </button>
      </div> */}
    </div>
  );
};

export default Support;
