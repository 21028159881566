import React, { useEffect, useState } from "react";
import "@/assets/css/BranchMap.css";

interface Address {
  id: number;
  street: string;
  city: string;
  province: string;
  province_code: string;
  postal_code: string;
  country: string;
  country_code: string;
  latitude: number;
  longitude: number;
}

interface BranchMapProps {
  address: Address;
  phoneNumber: string;
  website: string;
  branchName: string;
}

const BranchMap: React.FC<BranchMapProps> = ({
  address,
  phoneNumber,
  website,
  branchName,
}) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    const userData = localStorage.getItem("user_data");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      const selectedBranchLogo = parsedUserData.selectedBranch?.logo;
      if (selectedBranchLogo) {
        setLogoUrl(selectedBranchLogo);
      }
    }
  }, []);

  const fullAddress = `${address.street}, ${address.city}, ${address.province} ${address.postal_code}, ${address.country}`;
  const { latitude, longitude } = address;

  return (
    <div className="branch-map-container">
      <div className="branch-info">
        <h2 className="branch-name">{branchName}</h2>
        <p className="address-title">Address:</p>
        <p className="address">{fullAddress}</p>
        <p className="phone-title">Phone number:</p>
        <p className="phone-number">{phoneNumber}</p>
      </div>
      <div className="map-container">
        <iframe
          width="100%"
          height="100%"
          title="Branch Location Map"
          frameBorder="0"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAnVKeSTixBr0zPPECufZ1qoU6dEsfPF5o&q=${latitude},${longitude}`}
          allowFullScreen
        ></iframe>
        {/* <div className="map-overlay">
          <div className="map-overlay-content">
            {logoUrl && (
              <img src={logoUrl} alt="Branch Logo" className="branch-logo" />
            )}
            <span className="branch-name-overlay">{branchName}</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BranchMap;
