import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

interface PrinterHeaderProps {
  onSetupPrinters: () => void;
  onMenuItemClick: (componentName: string) => void;
  warningMessage?: string;
}

const PrinterHeader: React.FC<PrinterHeaderProps> = ({
  onSetupPrinters,
  onMenuItemClick,
  warningMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      {/* Title */}
      <div className="mb-4">
        <h1 className="text-2xl font-semibold">
          {t("back.management.printer.title")}
        </h1>
        <p className="text-lg font-bold">
          {t("back.management.printer.subtitle")}
        </p>
      </div>

      {/* Buttons */}
      <div className="flex flex-col sm:flex-row sm:items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
        <button
          onClick={() => onMenuItemClick("setupPrinter")}
          className="w-full sm:w-auto px-4 py-2 bg-black text-white rounded-md font-bold shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          {t("back.management.printer.learnHowToSetUp")}
        </button>
        <div className="relative w-full sm:w-auto">
          <button
            onClick={onSetupPrinters}
            className="w-full sm:w-auto px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            data-tooltip-id="setup-printer-tooltip"
            data-tooltip-content={warningMessage}
          >
            {t("back.management.printer.setUpPrinters")}
            {warningMessage && (
              <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
            )}
          </button>
          {warningMessage && <Tooltip id="setup-printer-tooltip" />}
        </div>
      </div>
    </div>
  );
};

export default PrinterHeader;
