import PageLoader from "@/components/back/Spinner";
import OperatingHoursInput from "@/components/ui/selectors/OperatingHoursInput";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OptionListSelectionModal from "@/components/ui/modals/OptionListSelectionModal";
import { Card, CardHeader, CardContent } from "@/components/back/Card";
import toast from "react-hot-toast";
import { Category, OperatingHour, OptionList } from "@/utils/types";

interface EditCategoryProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  categoryId: number | null;
}

const EditCategory = ({
  onMenuItemClick,
  setSuccessMessage,
  categoryId,
}: EditCategoryProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [category, setCategory] = useState<Category>({
    id: 0,
    name: "",
    description: "",
    status: "",
    parent_category: null,
    option_lists: [],
  });
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [newOperatingHours, setNewOperatingHours] = useState<OperatingHour[]>(
    []
  );
  const [isOptionListModalOpen, setIsOptionListModalOpen] = useState(false);
  const [originalCategory, setOriginalCategory] = useState<Category | null>(
    null
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteHourId, setDeleteHourId] = useState<number | null>(null);

  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${categoryId}/`);
          setCategory(response.data);
          setOriginalCategory(response.data);
          setOperatingHours(response.data.category_hours || []);
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    if (categoryId) {
      fetchCategory();
    }
  }, [axiosInstance, loading, categoryId, user?.selectedBranch]);

  useEffect(() => {
    if (originalCategory) {
      const isChanged =
        JSON.stringify(category) !== JSON.stringify(originalCategory);
      setIsDirty(isChanged);
    }
  }, [category, originalCategory]);

  const handleUpdateCategory = async () => {
    setIsSaving(true);
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";

        const transformedOptionLists = category.option_lists.map(
          (optionList) => ({
            id: optionList.id,
            name: optionList.name,
            branch: optionList.branch,
            options: optionList.options.map((option) => option.id),
            required: optionList.required,
            option_type: optionList.option_type,
            max_selections: optionList.max_selections,
          })
        );

        const updatedCategory = {
          name: category.name,
          description: category.description,
          status: category.status,
          branch: user.selectedBranch.id,
          parent_category: category.parent_category || null,
          option_lists: transformedOptionLists,
          ranking: category.ranking,
        };

        await axiosInstance.put(`${apiUrl}${categoryId}/`, updatedCategory, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const hoursApiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_HOURS ?? "";
        for (const hour of newOperatingHours) {
          await axiosInstance.post(hoursApiUrl, {
            day: hour.day,
            open_time: hour.open_time,
            close_time: hour.close_time,
            category: category.id,
          });
        }

        toast.success(t("back.management.menu.category.successupdate"));
        setIsDirty(false); // Reset dirty state after successful save
        onMenuItemClick(
          "category",
          t("back.management.menu.category.successupdate")
        );
      }
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error(t("back.management.menu.category.updatefail"));
    } finally {
      setIsSaving(false);
    }
  };

  const handleOptionListSave = (selectedOptionLists: OptionList[]) => {
    const updatedOptionLists = selectedOptionLists.map((optionList) => {
      const existingOptionList = category.option_lists.find(
        (existingList) => existingList.id === optionList.id
      );

      return existingOptionList
        ? { ...existingOptionList, ...optionList }
        : optionList;
    });

    setCategory((prevCategory) => ({
      ...prevCategory,
      option_lists: updatedOptionLists,
    }));

    setIsOptionListModalOpen(false);
  };

  const handleRemoveOptionList = (id: number) => {
    setCategory((prevCategory) => ({
      ...prevCategory,
      option_lists: prevCategory.option_lists.filter(
        (optionList) => optionList.id !== id
      ),
    }));
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        <h1 className="text-xl font-bold">
          {t("back.management.menu.category.editcategory")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-full px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="categoryName"
              >
                {t("back.management.menu.category.categoryname")}
              </label>
              <input
                type="text"
                id="itemName"
                placeholder="Enter category name"
                value={category.name}
                onChange={(e) =>
                  setCategory({ ...category, name: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.category.description")}
            </label>
            <textarea
              id="description"
              placeholder="Enter category description"
              value={category.description}
              onChange={(e) =>
                setCategory({ ...category, description: e.target.value })
              }
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            />
          </div>
        </div>

        {/* Option List Section */}
        <Card className="mb-4">
          <CardHeader className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">{t("Linked OptionLists")}</h2>
            <button
              onClick={() => setIsOptionListModalOpen(true)}
              className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
            >
              {t("Edit")}
            </button>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-2">
              {category.option_lists.map((optionList) => (
                <span
                  key={optionList.id}
                  className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                >
                  {optionList.name}
                  <button
                    onClick={() => handleRemoveOptionList(optionList.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </span>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Status Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.status")}
          </h2>
          <select
            value={category.status}
            onChange={(e) =>
              setCategory({ ...category, status: e.target.value })
            }
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.category.selectstatus")}
            </option>
            <option value="Active">
              {t("back.management.menu.category.status1")}
            </option>
            <option value="Inactive">
              {t("back.management.menu.category.status2")}
            </option>
            <option value="No Product">
              {t("back.management.menu.category.status3")}
            </option>
            <option value="Draft">
              {t("back.management.menu.category.status4")}
            </option>
          </select>
        </div>

        {/* Operating Hours Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.operatinghours")}
          </h2>
          <OperatingHoursInput
            operatingHours={newOperatingHours}
            setOperatingHours={setNewOperatingHours}
          />
          {operatingHours.map((hour) => (
            <div key={hour.id} className="flex items-center justify-between">
              <div>
                <p>{`${hour.day}: ${hour.open_time} - ${hour.close_time}`}</p>
              </div>
              <button
                onClick={() => {
                  setShowDeleteConfirmation(true);
                  setDeleteHourId(hour.id);
                }}
                className="text-red-500"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("category")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.category.cancelbutton")}
          </button>
          <button
            onClick={handleUpdateCategory}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.menu.category.updatebutton")}
          </button>
        </div>

        <OptionListSelectionModal
          isOpen={isOptionListModalOpen}
          onClose={() => setIsOptionListModalOpen(false)}
          onSave={handleOptionListSave}
          initialSelectedOptionLists={category.option_lists.map(
            (optionList) => optionList.id
          )}
        />
      </div>
    </PageLoader>
  );
};

export default EditCategory;
