import React from "react";

interface PrintableOrderDetailsProps {
  order: any;
}

const formatDeliveryAddress = (addressObj: any): string => {
  if (!addressObj) return "N/A";
  const { address, city, region, country, postal } = addressObj;
  return `${address}, ${city}, ${region}, ${country} ${postal}`.trim();
};

const PrintableOrderDetails: React.FC<PrintableOrderDetailsProps> = ({
  order,
}) => (
  <div className="bg-white p-6 max-w-md mx-auto border border-gray-300 rounded-lg shadow-md">
    <div className="flex justify-between items-center mb-2">
      <h2 className="text-2xl font-bold uppercase">{order.order_type}</h2>
      <span className="text-2xl font-bold">{order.order_number}</span>
    </div>

    <div className="border-b-2 border-dotted border-gray-400 mb-4"></div>

    <div className="mb-4">
      <p>
        <span className="font-semibold">Name:</span>{" "}
        {order.customer_name || "N/A"}
      </p>
      <p>
        <span className="font-semibold">Phone:</span>{" "}
        {order.customer_phone || "N/A"}
      </p>
      <p>
        <span className="font-semibold">OrderID:</span> {order.order_name}
      </p>
      <p>
        <span className="font-semibold">{order.payment_status}:</span>{" "}
        {new Date(order.created_at).toLocaleString()}
      </p>
    </div>

    <div className="border-t-2 border-b-2 border-dotted border-gray-400 py-4 my-4">
      {order.order_items.map((item: any, index: number) => (
        <div key={`${item.product_id}-${index}`} className="mb-2">
          <div className="flex justify-between items-start">
            <div>
              <span className="font-semibold">{item.quantity}</span>
              <span className="ml-2">{item.product_name}</span>
            </div>
            <span>${item.price_set}</span>
          </div>
          {item.options.map((option: any) => (
            <div
              key={option.id}
              className="text-sm text-gray-600 ml-4 flex justify-between"
            >
              <span>
                {option.name} x{option.quantity}
              </span>
              <span>{option.surcharge > 0 && ` +$${option.surcharge}`}</span>
            </div>
          ))}
        </div>
      ))}
    </div>

    {order.order_comments && (
      <div className="mb-4">
        <h3 className="font-semibold">Comments</h3>
        <p className="text-gray-600 border border-gray-300 p-2 rounded bg-gray-50">
          {order.order_comments}
        </p>
      </div>
    )}

    <div className="space-y-2">
      <div className="flex justify-between">
        <span>Subtotal</span>
        <span>${order.subtotal_price}</span>
      </div>
      <div className="flex justify-between">
        <span>Tax:</span>
        <span>${order.tax || "0.00"}</span>
      </div>
      <div className="flex justify-between">
        <span>Tip:</span>
        <span>${order.tip || "0.00"}</span>
      </div>
      <div className="flex justify-between">
        <span>Service Fee:</span>
        <span>${order.surcharge_fee || "0.00"}</span>
      </div>
      {order.order_type.toLowerCase() === "delivery" && (
        <div className="flex justify-between">
          <span>Delivery Fee:</span>
          <span>${order.delivery_fee || "N/A"}</span>
        </div>
      )}
      <div className="flex justify-between font-bold">
        <span>Total:</span>
        <span>${order.total_price}</span>
      </div>
    </div>

    {order.order_type.toLowerCase() === "delivery" && (
      <div className="mt-4">
        <p className="font-semibold">Delivery Address:</p>
        <p>{formatDeliveryAddress(order.delivery_address)}</p>
      </div>
    )}

    <div className="mt-6 text-center border-t-2 border-dotted border-gray-400 pt-4">
      <span className="text-xl font-bold uppercase">
        {order.payment_status}
      </span>
    </div>
  </div>
);

export default PrintableOrderDetails;
