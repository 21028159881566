import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useUser } from "@/contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardContent } from "@/components/back/Card";

interface Category {
  id: number;
  name: string;
}

interface CategorySelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedCategories: Category[]) => void;
  initialSelectedCategories: number[];
}

const CategorySelectionModal: React.FC<CategorySelectionModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialSelectedCategories,
}) => {
  const { t } = useTranslation();
  const [axiosInstance, loading] = useAxiosInstance();
  const { user } = useUser();
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<number[]>(
    initialSelectedCategories
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const itemsPerPage = 20;

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore]
  );

  useEffect(() => {
    if (isOpen) {
      setCategories([]);
      setSearchQuery("");
      setCurrentPage(1);
      setHasMore(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen, currentPage, searchQuery]);

  const fetchCategories = async () => {
    if (loading || !axiosInstance || !user?.selectedBranch || isFetching)
      return;
    setIsFetching(true);
    try {
      const apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
      let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;

      if (searchQuery) {
        url += `&name=${encodeURIComponent(searchQuery)}`;
      }

      const response = await axiosInstance.get(url);
      setCategories((prev) =>
        currentPage === 1
          ? response.data.results
          : [...prev, ...response.data.results]
      );
      setHasMore(response.data.next !== null);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setCategories([]);
    fetchCategories();
  };

  const handleCategoryToggle = (categoryId: number) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleSave = () => {
    const selectedCategoryObjects = categories.filter((category) =>
      selectedCategories.includes(category.id)
    );
    onSave(selectedCategoryObjects); 
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg h-[80vh] flex flex-col">
        <CardHeader className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{t("Select Categories")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </CardHeader>
        <div className="px-6 py-4">
          <div className="mb-4 flex">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t("Search Category...")}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md"
            />
            <button
              onClick={handleSearch}
              className="px-3 py-2 bg-gray-500 text-white rounded-r-md"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <CardContent className="flex-grow overflow-y-auto">
          {categories.map((category, index) => (
            <div
              key={category.id}
              ref={index === categories.length - 1 ? lastItemRef : null}
              className="flex items-center p-2"
            >
              <input
                type="checkbox"
                id={`category-${category.id}`}
                checked={selectedCategories.includes(category.id)}
                onChange={() => handleCategoryToggle(category.id)}
                className="mr-2"
              />
              <label htmlFor={`category-${category.id}`}>{category.name}</label>
            </div>
          ))}
          {isFetching && <div className="text-center py-2">Loading...</div>}
        </CardContent>
        <div className="flex justify-end p-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("Confirm")}
          </button>
        </div>
      </Card>
    </div>
  );
};

export default CategorySelectionModal;
