import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import DataTable, { Column } from "@/components/back/DataTable";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrinterHeader from "./PrinterHeader";
import SmallSpinner from "@/components/front/Loader/SmallSpinner";
import toast from "react-hot-toast";
import { Card, CardContent, CardHeader } from "@/components/back/Card";

interface Printer {
  id: number;
  name: string;
  is_primary: boolean;
  is_report_printer: boolean;
  printer_id: number;
  state: string;
}
interface Computer {
  computer_id: number;
  name: string;
  state: string;
  last_seen: string;
}

interface SortState {
  field: keyof Printer;
  direction: "asc" | "desc";
}

interface FilterState {
  state: string;
}

interface PrinterTableProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => void;
  successMessage: string;
}

export default function PrinterTable({
  onMenuItemClick,
  successMessage,
}: PrinterTableProps) {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [computers, setComputers] = useState<Computer[]>([]);
  const [isComputerStatusLoading, setIsComputerStatusLoading] = useState(false);
  const prevComputersRef = useRef<Computer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    direction: "asc",
  });
  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterState, setFilterState] = useState<FilterState>({
    state: "",
  });

  const columns: Column<Printer>[] = [
    {
      key: "name",
      header: t("back.management.printer.table.name"),
      sortable: true,
      bold: true,
    },
    {
      key: "is_primary",
      header: t("back.management.printer.table.isDefault"),
      sortable: true,
      render: (printer: Printer) => (printer.is_primary ? "Yes" : "No"),
    },
    {
      key: "is_report_printer",
      header: t("back.management.printer.table.isReportPrinter"),
      sortable: true,
      render: (printer: Printer) => (printer.is_report_printer ? "Yes" : "No"),
    },
    {
      key: "printer_id",
      header: t("back.management.printer.table.printerId"),
      sortable: true,
    },
    {
      key: "state",
      header: t("back.management.printer.table.state"),
      sortable: true,
    },
    {
      key: "action",
      header: t("back.management.menu.product.table.action"),
      render: (printer: Printer) => (
        <button onClick={() => editItem(printer.id)} className="mr-2">
          <FontAwesomeIcon
            icon={faEdit}
            className="text-gray-600 hover:text-gray-800 fa-2x"
          />
        </button>
      ),
    },
  ];

  const getStatusColor = (printer: Printer) => {
    return printer.state === "online" ? "bg-green-500" : "bg-gray-500";
  };

  useEffect(() => {
    fetchPrinters();
  }, [
    currentPage,
    searchQuery,
    sortState,
    filterState,
    filterStartDate,
    filterEndDate,
  ]);

  useEffect(() => {
    if (successMessage) {
      setBannerMessage(successMessage);
      setShowBanner(true);
      setIsSuccess(true);
    }
  }, [successMessage]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const fetchPrinters = useCallback(async () => {
    try {
      // Check readiness inside the try block
      if (!axiosInstance || !user?.selectedBranch || loading) {
        // If conditions are not met, simply return without logging
        return;
      }

      // Proceed with fetching logic if ready
      let url = `print/printers/?branch_id=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;
      if (searchQuery) url += `&name=${encodeURIComponent(searchQuery)}`;
      if (filterState.state) url += `&state=${filterState.state}`;

      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        setPrinters(response.data.results);
        setTotalItems(response.data.count);
      } else {
        throw new Error("Failed to fetch printers");
      }
    } catch (error) {
      console.error("Error fetching printers:", error);
      setPrinters([]);
      setBannerMessage("Failed to fetch printers.");
      setIsSuccess(false);
      setShowBanner(true);
    }
  }, [
    axiosInstance,
    user?.selectedBranch,
    currentPage,
    itemsPerPage,
    searchQuery,
    filterState.state,
    loading,
  ]);

  useEffect(() => {
    fetchPrinters();
  }, [fetchPrinters]);

  const fetchComputerStatus = useCallback(async () => {
    try {
      // Check readiness inside the try block
      if (!axiosInstance || !user?.selectedBranch || loading) {
        // If conditions are not met, simply return without logging
        return;
      }

      setIsComputerStatusLoading(true);
      const response = await axiosInstance.get(
        `print/branch/status/?branch_id=${user.selectedBranch.id}`
      );

      if (response.status === 200) {
        const newComputers = response.data.computers;
        // Compare with previous data to determine if updates are needed
        if (
          JSON.stringify(newComputers) !==
          JSON.stringify(prevComputersRef.current)
        ) {
          setComputers(newComputers);
          prevComputersRef.current = newComputers;
        }
      } else {
        throw new Error("Failed to fetch computer status");
      }
    } catch (error) {
      console.error("Error fetching computer status:", error);
    } finally {
      setIsComputerStatusLoading(false);
    }
  }, [axiosInstance, user?.selectedBranch, loading]);

  useEffect(() => {
    fetchComputerStatus(); // Fetch immediately on mount
    const intervalId = setInterval(fetchComputerStatus, 60000); // Poll every minute

    return () => clearInterval(intervalId);
  }, [fetchComputerStatus]);

  const renderComputerStatusCard = () => (
    <Card className="mb-6 relative">
      <CardHeader className="font-semibold">
        {t("back.management.printer.computerStatus")}
      </CardHeader>
      <CardContent>
        {isComputerStatusLoading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
            <SmallSpinner />
          </div>
        )}
        {computers.map((computer) => (
          <div
            key={computer.computer_id}
            className="flex justify-between items-center mb-2"
          >
            <span>{computer.name}</span>
            <span
              className={`px-2 py-1 rounded-full ${
                computer.state === "connected" ? "bg-green-500" : "bg-red-500"
              } text-white`}
            >
              {computer.state}
            </span>
          </div>
        ))}
      </CardContent>
    </Card>
  );

  const editItem = (id: number) => {
    onMenuItemClick("editPrinter", "", id);
  };

  const handleSortChange = (field: keyof Printer) => {
    setSortState((prevState) => ({
      field,
      direction:
        prevState.field === field && prevState.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const setupPrinters = async () => {
    try {
      if (!axiosInstance || !user?.selectedBranch) {
        console.error("Axios instance or selected branch not available");
        return;
      }

      const response = await axiosInstance.post("/print/setup_printers/", {
        branch_id: user.selectedBranch.id,
      });

      if (response.status === 200) {
        setBannerMessage("Printers set up successfully");
        setIsSuccess(true);
        setShowBanner(true);
        fetchPrinters();
      } else {
        throw new Error(
          "Please make sure you have the latest client downloaded and logged in with the provided credentials."
        );
      }
    } catch (error) {
      console.error("Error setting up printers:", error);
      setBannerMessage(
        "Please make sure you have the latest client downloaded and logged in with the provided credentials."
      );
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  const handleSetupPrinters = async () => {
    try {
      await setupPrinters();
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.error(
          "Please make sure you have the latest client downloaded and logged in with the provided credentials."
        );
      } else {
        console.error("Error setting up printers:", error);
        toast.error(
          "Please make sure you have the latest client downloaded and logged in with the provided credentials."
        );
      }
    }
  };

  const renderMobileCard = (printer: Printer) => (
    <div className="flex items-center justify-between w-full">
      <div className="flex flex-col">
        <p className="font-bold text-gray-900">{printer.name}</p>
        <p className="text-gray-700">
          Primary: {printer.is_primary ? "Yes" : "No"}
        </p>
        <p className="text-gray-700">
          Report Printer: {printer.is_report_printer ? "Yes" : "No"}
        </p>
        <p className="text-gray-700">ID: {printer.printer_id}</p>
        <p className="text-gray-700">State: {printer.state}</p>
      </div>
      <button onClick={() => editItem(printer.id)} className="p-2">
        <FontAwesomeIcon
          icon={faEdit}
          className="text-gray-600 hover:text-gray-800 fa-2x"
        />
      </button>
    </div>
  );

  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          <PrinterHeader
            onSetupPrinters={handleSetupPrinters}
            onMenuItemClick={onMenuItemClick}
          />

          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}

          {renderComputerStatusCard()}

          <DataTable
            data={printers}
            columns={columns}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            totalItems={totalItems}
            sortState={sortState}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            renderMobileCard={renderMobileCard}
            onEdit={editItem}
            onDelete={() => {}}
            getStatusColor={getStatusColor}
          />
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}
