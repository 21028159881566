import React from "react";
import { format, parse } from "date-fns";

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

const formatTime = (time: string) => {
  const parsedTime = parse(time, "HH:mm:ss", new Date());
  return format(parsedTime, "HH:mm");
};

const mergeOperatingHours = (
  hours: OperatingHour[]
): Record<string, string[]> => {
  const mergedHours: Record<string, string[]> = {};

  hours.forEach((hour) => {
    if (!mergedHours[hour.day]) {
      mergedHours[hour.day] = [];
    }
    mergedHours[hour.day].push(
      `${formatTime(hour.open_time)} - ${formatTime(hour.close_time)}`
    );
  });

  return mergedHours;
};

const MobileOperationalTimes: React.FC<{ operatingHours: OperatingHour[] }> = ({
  operatingHours,
}) => {
  const mergedHours = mergeOperatingHours(operatingHours);
  const daysOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <ul className="mobile-operational-times">
      {daysOrder.map((day) => (
        <li key={day} className="flex justify-between items-start">
          <span className="day">{day}</span>
          <div className="times">
            {mergedHours[day] ? (
              mergedHours[day].map((timeRange, index) => (
                <div key={index}>{timeRange}</div>
              ))
            ) : (
              <span>Closed</span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MobileOperationalTimes;
