import Logo from "@/components/front/Logo";
import {
  CalculatorIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
  ListBulletIcon,
  PrinterIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import {
  IdentificationIcon,
  InformationCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  onMenuItemClick: (componentName: string) => void;
  isOpen: boolean;
  onClose: () => void;
}

const SIDEBAR_MENU = [
  {
    id: 1,
    name: "dashboard",
    icon: <Squares2X2Icon />,
    title: "back.management.sidebar.dashboard",
    children: [],
  },
  {
    id: 2,
    name: "menu",
    icon: <ListBulletIcon />,
    title: "back.management.sidebar.menu.title",
    children: [
      {
        id: 2.1,
        name: "category",
        title: "back.management.sidebar.menu.category",
      },
      {
        id: 2.2,
        name: "product",
        title: "back.management.sidebar.menu.product",
      },
      {
        id: 2.3,
        name: "optionList",
        title: "back.management.sidebar.menu.optionList",
      },
      {
        id: 2.4,
        name: "option",
        title: "back.management.sidebar.menu.option",
      },
      {
        id: 2.5,
        name: "upsell",
        title: "back.management.sidebar.menu.upsell",
      },
    ],
  },
  {
    id: 3,
    name: "orders",
    icon: <ClipboardDocumentListIcon />,
    title: "back.management.sidebar.orderToday",
    children: [],
  },
  {
    id: 4,
    name: "report",
    icon: <CalculatorIcon />,
    title: "back.management.sidebar.report",
    children: [],
  },
  {
    id: 5,
    name: "printer",
    icon: <PrinterIcon />,
    title: "back.management.sidebar.printer",
    children: [],
  },
  {
    id: 6,
    name: "customerloyalty",
    icon: <IdentificationIcon />,
    title: "back.management.sidebar.customers.loyalty",
    children: [],
  },
  {
    id: 7,
    name: "business",
    icon: <InformationCircleIcon />,
    title: "back.management.sidebar.business.title",
    children: [
      {
        id: 7.1,
        name: "devices",
        title: "back.management.sidebar.business.device",
      },
      {
        id: 7.2,
        name: "businessInformation",
        title: "back.management.sidebar.business.information",
      },
    ],
  },
  {
    id: 11,
    name: "support",
    icon: <ChatBubbleBottomCenterTextIcon />,
    title: "back.management.sidebar.support",
    children: [],
  },
  {
    id: 12,
    name: "setting",
    icon: <Cog6ToothIcon />,
    title: "back.management.sidebar.settings",
    children: [],
  },
];

export default function Sidebar({
  onMenuItemClick,
  isOpen,
  onClose,
}: SidebarProps) {
  const { t } = useTranslation();
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleMenuItemClick = (componentName: string) => {
    setSelectedItem(componentName);
    onMenuItemClick(componentName);
    onClose();
  };

  const handleToggleSubMenu = (subMenu: string) => {
    setOpenSubMenu(openSubMenu === subMenu ? null : subMenu);
  };

  const [sidebarHeight, setSidebarHeight] = useState("100vh");

  useEffect(() => {
    const updateSidebarHeight = () => {
      setSidebarHeight(`${window.innerHeight}px`);
    };

    window.addEventListener("resize", updateSidebarHeight);
    updateSidebarHeight();

    return () => window.removeEventListener("resize", updateSidebarHeight);
  }, []);

  return (
    <aside
      className={`fixed top-0 left-0 h-screen w-64 bg-white text-black z-40 transform transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:relative overflow-y-auto`}
      style={{ height: sidebarHeight }}
      aria-label="Sidebar"
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-center mb-5 p-2.5">
          <Logo size="h-12" />
        </div>

        {SIDEBAR_MENU.map((menu) => (
          <div key={menu.id}>
            {menu.children.length === 0 ? (
              <button
                onClick={() => {
                  handleMenuItemClick(menu.name);
                  handleToggleSubMenu("");
                }}
                className={`flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full ${
                  selectedItem === menu.name
                    ? "bg-gray-200 text-gray-800 border-l-4 border-l-red-500"
                    : ""
                }`}
              >
                <div className="w-5 mr-2.5 flex justify-center">
                  {menu.icon}
                </div>
                <span>{t(menu.title)}</span>
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    setSelectedItem(null);
                    handleToggleSubMenu(menu.name);
                  }}
                  className={`flex items-center justify-between p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full
              ${openSubMenu === menu.name ? "bg-gray-200 text-gray-800" : ""}
                `}
                >
                  <div className="flex items-center">
                    <div className="w-5 mr-2.5 flex justify-center">
                      {menu.icon}
                    </div>
                    <span>{t(menu.title)}</span>
                  </div>
                  {openSubMenu === menu.name ? (
                    <ChevronUpIcon className="h-4 w-4 text-black" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4 text-black" />
                  )}
                </button>
                <div
                  className={`overflow-hidden ${
                    openSubMenu === menu.name
                      ? "transition-max-height duration-700"
                      : "transition-max-height duration-300"
                  } ease-in-out ${
                    openSubMenu === menu.name ? "max-h-96" : "max-h-0"
                  }`}
                >
                  {menu.children.map((child) => (
                    <button
                      key={child.id}
                      onClick={() => handleMenuItemClick(child.name)}
                      className={`inline-flex justify-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full ${
                        selectedItem === child.name
                          ? "bg-gray-200 text-gray-800 border-l-4 border-l-red-500"
                          : ""
                      }`}
                    >
                      {t(child.title)}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </aside>
  );
}
