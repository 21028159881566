import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  groups: string[];
  access_token: string;
  avatar?: string;
  branches: Branch[];
  selectedBranch?: Branch | null;
  owner?: any;
  business_name?: string;
}

interface Branch {
  id: number;
  name: string;
  is_open: boolean;
  points_to_currency_multiplier: string;
  points_conversion_rate: string;
  min_points_to_redeem: string;
  max_points_to_redeem: string;
  loyalty_program_enabled: boolean;
  min_order_to_redeem_points: string;
  upsell_feature: boolean;
}

interface UserContextType {
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

  // Load user data from local storage on mount
  useEffect(() => {
    const storedUserData = localStorage.getItem("user_data");
    if (storedUserData) {
      const userData: User = JSON.parse(storedUserData);
      setUser(userData);
    }
  }, []);

  const login = (userData: User) => {
    // Set the user state
    setUser(userData);

    // Update both access_token and full user data including selectedBranch
    localStorage.setItem("access_token", userData.access_token);
    localStorage.setItem("user_data", JSON.stringify(userData));
  };

  const updateSelectedBranch = (branch: Branch) => {
    if (!user) return;

    // Update selectedBranch in user object
    const updatedUser = { ...user, selectedBranch: branch };
    setUser(updatedUser); // Update the state

    // Update the user data in localStorage with selectedBranch
    localStorage.setItem("user_data", JSON.stringify(updatedUser));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
